import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Icons
import FeatherIcon from "feather-icons-react";

import {fire} from "../../../Fire.js";
import recoveryimg from "../../../assets/images/user/recovery.svg";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const ForgotPassword = props => {
  const [isSnackbarOpen, setOpenSnackbar] = React.useState(false);
  const [formError, setFormError] = React.useState("");

  const sendPasswordReset = (e) => {
    e.preventDefault();
    const email = document.getElementById('email').value;
    fire.auth().sendPasswordResetEmail(email)
    .then(() => {
      // ..
      setOpenSnackbar(true);
    })
    .catch((error) => {
      //var errorCode = error.code;
      var errorMessage = error.message;
      setFormError(errorMessage);
      // ..
    });
      
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };


  return (
    <React.Fragment>
      <div className="back-to-home rounded d-none d-sm-block">
        <Link to="//swhag.co"
          target="_blank" rel="noopener noreferrer"
          className="btn btn-icon btn-soft-primary">
          <i>
            <FeatherIcon icon="home" className="icons" />
          </i>
        </Link>
      </div>

      <section className="bg-home d-flex align-items-center">
          <Container>
            <Row className="align-items-center">
              <Col lg="7" md="6">
                <div className="me-lg-5">
                  <img
                    src={recoveryimg}
                    className="img-fluid d-block mx-auto"
                    alt=""
                  />
                </div>
              </Col>
              <Col lg="5" md="6">
                <Card className="shadow rounded border-0">
                  <CardBody>
                    <h4 className="card-title text-center">Recover Account</h4>
                    <div style={{color: "red", fontSize: 12}}>{formError}</div>
                    <AvForm className="login-form mt-4">
                      <Row>
                        <Col lg="12">
                          <p className="text-muted">
                            Please enter your email address. You will receive a
                            link to create a new password via email.
                          </p>
                          <div className="mb-3">
                            <Label className="form-label" for="email">
                              Email address{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="mail"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <AvField
                              type="text"
                              className="form-control ps-5"
                              name="email"
                              id="email"
                              placeholder="Enter Your Email Address"
                              required
                              errorMessage=""
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Please enter your email",
                                },
                                pattern: {
                                  value:
                                    "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                                  errorMessage: "E-Mail is not valid!",
                                },
                              }}
                            />
                          </div>
                        </Col>
                        <Col lg="12">
                          <div className="d-grid">
                            <Button color="primary" onClick={sendPasswordReset}>
                              Send
                          </Button>
                          </div>
                        </Col>
                        <div className="mx-auto">
                          <p className="mb-0 mt-3">
                            <small className="text-dark me-2">
                              Remember your password ?
                            </small>{" "}
                            <Link
                              to="/"
                              className="text-dark fw-bold"
                            >
                              Sign in
                            </Link>
                          </p>
                        </div>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                  You have been sent a link via email to reset your password
                </Alert>
              </Snackbar>
            </Row>
          </Container>
        </section>
    </React.Fragment>
  );
}

export default ForgotPassword;
