import React from "react";
import {
  Container,
  Row,
  Col,
  Table,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import Slick Slider CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


//Import Images
import client from "../assets/images/icon.png";
import {fire, fire2} from "../Fire.js";

function logout() {
  fire.auth().signOut();
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function useProfile() {
  const [profile, setProfile] = React.useState({});
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    var categories = {
      Gaming: false,
      Beauty: false,
      Health: false,
      "Women's Fashion": false,
      "Men's Fashion": false,
      Accessories: false,
      "Gym and Fitness": false,
      Electronics: false,
    };
    const unsubscribe = fire
    .firestore()
    .collection("shoppers")
    .doc(user.email)
    .onSnapshot(document => {
      const doc = document.data();
      doc.shoppingInterests.forEach((category) => {
        categories = Object.assign(categories, {[category]: true})
      });
      setProfile(Object.assign(document.data(), {categories}));
    });
    return () => unsubscribe;
  }, [user.email]);
  
  return profile;
};

function useStores() {
  const [stores, setStores] = React.useState([]);
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("shoppers")
    .doc(user.email)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      setStores([]);
      querySnapshot.forEach((queryDocumentSnapshot) => {
        setStores(currentStores => [...currentStores, Object.assign(queryDocumentSnapshot.data(), {id: queryDocumentSnapshot.id})]);
      });
    });
    return () => unsubscribe;
  }, [user.email]);
  
  return stores;
};

function useOrders() {
  const [orders, setOrders] = React.useState([]);

  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("shoppers")
    .doc(user.email)
    .collection("orders")
    .get()
    .then(orders => {
      setOrders([]);
      orders.forEach(order => {
        const products = [];
        fire
        .firestore()
        .collection("shoppers")
        .doc(user.email)
        .collection("orders")
        .doc(order.id)
        .collection("products")
        .get()
        .then(productsOrdered => {
          productsOrdered.forEach(productOrdered => {
            products.push(productOrdered.data());
          });
        });
        setOrders(currentOrders => [...currentOrders, Object.assign(order.data(), {products: products, orderId: order.id})]);
      });
    });
    return () => unsubscribe;
  }, [user.email]);
  
  return orders;
};

function useProducts() {
  const [allProducts, setAllProducts] = React.useState([]);
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    var allProducts2 = [];
    const unsubscribe = fire
    .firestore()
    .collection("shoppers")
    .doc(user.email)
    .collection("shoppingInterests")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach((queryDocumentSnapshot) => {
        allProducts2.push(Object.assign(queryDocumentSnapshot.data(), {id: queryDocumentSnapshot.id}));
      });
      setAllProducts(allProducts2);
    });
    return () => unsubscribe;
  }, [user.email]);
  
  return allProducts;
};

const Profile = props => {
  const user = fire.auth().currentUser;
  const profile = useProfile();
  const stores = useStores();
  const orders = useOrders();
  const allProducts = useProducts();
  const [activeTab, setActiveTab] = React.useState("1");
  const [isSnackbarSaveOpen, setOpenSnackbarSave] = React.useState(false);

  // For Orders page 
  const [products, setProducts] = React.useState([]);
  const [singleOrder, setSingleOrder] = React.useState({});
  const [isModalOpen, setOpenModal] = React.useState(false);
  const [timestamp, setTimestamp] = React.useState("");

  // For Shopping Interests
  const [categories, setCategories] = React.useState({
    "Gaming": false,
    "Beauty": false,
    "Health": false,
    "Women's Fashion": false,
    "Men's Fashion": false,
    "Accessories": false,
    "Gym and Fitness": false,
    "Electronics": false,
  });
  const [shoppingInterests, setShoppingInterests] = React.useState([]);

  React.useEffect(() => {
    if (profile.categories) {
      setCategories(profile.categories);
      setShoppingInterests(profile.shoppingInterests);
    };
    const queryParams = new URLSearchParams(window.location.search);
    const storeName = queryParams.get('store');
    if(storeName != null){
      const getStore = fire2.functions().httpsCallable('getStore');
      getStore({ storeName: storeName }).then(res => {
        const store = res.data;
        fire.firestore().collection("shoppers").doc(user.email).collection("stores").doc(store.storeId).set(store,{merge: true});

        if(profile.firstName){
          const saveShopperProfile = fire2.functions().httpsCallable('saveShopperProfile');
          saveShopperProfile({ store: store, firstName: profile.firstName, lastName: profile.lastName, phoneNumber: profile.phoneNumber, deliveryAddress: profile.deliveryAddress, email: user.email}).then(res => {
            console.log(res.data);
          });
        }
        if(profile.shoppingInterests){
          profile.shoppingInterests.forEach(category => {
            var allProducts = [];
            const ProductName = document.getElementById(category+"ProductName").value;
            const Price = Number(document.getElementById(category+"Price").value);
            const ProductName2 = document.getElementById(category+"ProductName2").value;
            const Price2 = Number(document.getElementById(category+"Price2").value);
            const product1 = Object.assign({},{name: ProductName, price: Price});
            const product2 = Object.assign({},{name: ProductName2, price: Price2});
            allProducts.push(product1);
            allProducts.push(product2);
            const saveShoppingInterests = fire2.functions().httpsCallable('saveShoppingInterests');
            saveShoppingInterests({ store: store, email: user.email, products: allProducts, category: category, shoppingInterests: profile.shoppingInterests, monthlyShoppingFrequency: profile.monthlyShoppingFrequency}).then(res => {
              console.log(res.data);
            });
          });
        }

      });
    };
  }, [profile, user.email]);

  const changeTab = (e) => {
    setActiveTab(e);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbarSave(false);
  };

  const updateProfile = (e) =>{
    e.preventDefault();
    const firstName = document.getElementById("firstName").value;
    const lastName = document.getElementById("lastName").value;
    const phoneNumber = document.getElementById("phoneNumber").value;
    const deliveryAddress = document.getElementById("deliveryAddress").value;

    setOpenSnackbarSave(true);
    fire.firestore().collection("shoppers").doc(user.email).update({
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      deliveryAddress: deliveryAddress,
    });

    stores.forEach((store) => {
      const saveShopperProfile = fire2.functions().httpsCallable('saveShopperProfile');
      saveShopperProfile({ store: store, firstName: firstName, lastName: lastName, phoneNumber: phoneNumber, deliveryAddress: deliveryAddress, email: user.email}).then(res => {
        console.log(res.data);
      });
    });
  };

  const openModal = (e) => {
    setOpenModal(true);
    setProducts(e.products);
    setSingleOrder(e);
    setTimestamp(e.timestamp.toDate().toDateString());
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleChange = (event) => {
    setCategories({
      ...categories,
      [event.target.name]: event.target.checked,
    });
    if(event.target.checked === true){
      setShoppingInterests(currentShopperCategories => [...currentShopperCategories, event.target.name]);
    } else{
      var list = shoppingInterests;
      list = list.filter((n) => {return n !== event.target.name});
      setShoppingInterests(list);
    };
  };

  //const { Gaming, Beauty, Health, WomenFashion, MensFashion, Accessories, GymFitness, Electronics } = categories;
  const Gaming = categories[Object.keys(categories)[0]];
  const Beauty = categories[Object.keys(categories)[1]];
  const Health = categories[Object.keys(categories)[2]];
  const WomenFashion = categories[Object.keys(categories)[3]];
  const MensFashion = categories[Object.keys(categories)[4]];
  const Accessories = categories[Object.keys(categories)[5]];
  const GymFitness = categories[Object.keys(categories)[6]];
  const Electronics = categories[Object.keys(categories)[7]];

  const updateInterests = (e) =>{
    e.preventDefault();
    const monthlyShoppingFrequency = Number(document.getElementById("monthlyShoppingFrequency").value);
    fire.firestore().collection("shoppers").doc(user.email).update({
      shoppingInterests: shoppingInterests,
      monthlyShoppingFrequency: monthlyShoppingFrequency,
    });
    shoppingInterests.forEach(category => {
      var allProducts = [];
      const ProductName = document.getElementById(category+"ProductName").value;
      const Price = Number(document.getElementById(category+"Price").value);
      const ProductName2 = document.getElementById(category+"ProductName2").value;
      const Price2 = Number(document.getElementById(category+"Price2").value);
      const product1 = Object.assign({},{name: ProductName, price: Price});
      const product2 = Object.assign({},{name: ProductName2, price: Price2});
      allProducts.push(product1);
      allProducts.push(product2);
      fire.firestore().collection("shoppers").doc(user.email).collection("shoppingInterests").doc(category).set({
        products: allProducts,
      });
      stores.forEach((store) => {
        const saveShoppingInterests = fire2.functions().httpsCallable('saveShoppingInterests');
        saveShoppingInterests({ store: store, email: user.email, products: allProducts, category: category, shoppingInterests: shoppingInterests, monthlyShoppingFrequency: monthlyShoppingFrequency}).then(res => {
          console.log(res.data);
        });
      });
    });
    setOpenSnackbarSave(true);
  };
  

  return(
    <React.Fragment>
      {/* breadcrumb */}
      {/* <PageBreadcrumb
        title="My Account"
        pathItems={pathItems}
      /> */}
      <section className="section">
        <Container>
          <Row>
            <Col md={4} className="mt-4 pt-2">
              <div className="d-flex align-items-center">
                <img
                  src={client}
                  className="avatar avatar-md-md rounded-circle"
                  alt=""
                />
                <div className="ms-3">
                  <h6 className="text-muted mb-0">Hello,</h6>
                  <h5 className="mb-0">{profile.firstName} {profile.lastName}</h5>
                </div>
              </div>

              <ul
                className="nav nav-pills nav-justified flex-column bg-white rounded mt-4 shadow p-3 mb-0"
                id="pills-tab"
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames(
                      { active: activeTab === "1" },
                      "rounded"
                    )}
                    onClick={() => {
                      changeTab("1");
                    }}
                  >
                    <div className="text-start py-1 px-3">
                      <h6 className="mb-0">
                        <i className="uil uil-dashboard h5 align-middle me-2 mb-0"></i>{" "}
                        Dashboard
                      </h6>
                    </div>
                  </NavLink>
                </NavItem>

                <NavItem className="mt-2">
                  <NavLink
                    className={classnames(
                      { active: activeTab === "2" },
                      "rounded"
                    )}
                    onClick={() => {
                      changeTab("2");
                    }}
                    to="#"
                  >
                    <div className="text-start py-1 px-3">
                      <h6 className="mb-0">
                        <i className="uil uil-user h5 align-middle me-2 mb-0"></i>{" "}
                        Contact information
                      </h6>
                    </div>
                  </NavLink>
                </NavItem>

                <NavItem className="mt-2">
                  <NavLink
                    className={classnames(
                      { active: activeTab === "3" },
                      "rounded"
                    )}
                    onClick={() => {
                      changeTab("3");
                    }}
                    to="#"
                  >
                    <div className="text-start py-1 px-3">
                      <h6 className="mb-0">
                        <i className="uil uil-shopping-cart h5 align-middle me-2 mb-0"></i>{" "}
                        Shopping interests
                      </h6>
                    </div>
                  </NavLink>
                </NavItem>

                <NavItem className="mt-2">
                  <NavLink
                    className={classnames(
                      { active: activeTab === "4" },
                      "rounded"
                    )}
                    onClick={() => {
                      changeTab("4");
                    }}
                    to="#"
                  >
                    <div className="text-start py-1 px-3">
                      <h6 className="mb-0">
                        <i className="uil uil-list-ul h5 align-middle me-2 mb-0"></i>{" "}
                        Orders
                      </h6>
                    </div>
                  </NavLink>
                </NavItem>

                <NavItem className="mt-2">
                  <NavLink
                    className={classnames(
                      { active: activeTab === "5" },
                      "rounded"
                    )}
                    onClick={() => {
                      changeTab("5");
                    }}
                    to="#"
                  >
                    <div className="text-start py-1 px-3">
                      <h6 className="mb-0">
                        <i className="uil uil-shop h5 align-middle me-2 mb-0"></i>{" "}
                        Stores
                      </h6>
                    </div>
                  </NavLink>
                </NavItem>

                <NavItem className="mt-2">
                  <Link className="nav-link rounded" onClick={logout}>
                    <div className="text-start py-1 px-3">
                      <h6 className="mb-0">
                        <i className="uil uil-sign-out-alt h5 align-middle me-2 mb-0"></i>{" "}
                        Logout
                      </h6>
                    </div>
                  </Link>
                </NavItem>
              </ul>
            </Col>

            <Col md={8} xs={12} className="mt-4 pt-2">
              <TabContent activeTab={activeTab}>
                <TabPane
                  className="fade bg-white show shadow rounded p-4"
                  tabId="1"
                >
                  <h6 className="text-muted">
                    Hello <span className="text-dark">{profile.firstName} {profile.lastName}</span>
                  </h6>

                  <h6 className="text-muted mb-0">
                    From your account dashboard you can view your{" "}
                    <Link to="#" onClick={() => {
                      changeTab("4");
                    }} className="text-danger">
                      recent orders
                    </Link>
                    , manage your{" "}
                    <Link to="#" onClick={() => {
                      changeTab("2");
                    }} className="text-danger">
                      shipping address
                    </Link>
                    , edit your{" "}
                    <Link to="#" onClick={() => {
                      changeTab("2");
                    }} className="text-danger">
                      contact information
                    </Link>
                    , and personalize your{" "}
                    <Link to="#" onClick={() => {
                      changeTab("3");
                    }} className="text-danger">
                      shopping Interests
                    </Link>
                    .
                  </h6>
                </TabPane>

                <TabPane
                  className="show fade bg-white shadow rounded p-4"
                  tabId="2"
                >
                  <Form>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">First Name</Label>
                          <div className="form-icon position-relative">
                            <FeatherIcon
                              icon="user"
                              className="fea icon-sm icons"
                            />
                            <input
                              name="name"
                              id="firstName"
                              type="text"
                              className="form-control ps-5"
                              defaultValue={profile.firstName}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">Last Name</Label>
                          <div className="form-icon position-relative">
                            <FeatherIcon
                              icon="user-check"
                              className="fea icon-sm icons"
                            />
                            <input
                              name="name"
                              id="lastName"
                              type="text"
                              className="form-control ps-5"
                              defaultValue={profile.lastName}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">Your Email</Label>
                          <div className="form-icon position-relative">
                            <FeatherIcon
                              icon="mail"
                              className="fea icon-sm icons"
                            />
                            <input
                              name="email"
                              id="email"
                              type="email"
                              className="form-control ps-5"
                              disabled
                              defaultValue={user.email}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="form-label">Phone No. :</Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="phone"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="number"
                            id="phoneNumber"
                            type="number"
                            className="form-control ps-5"
                            placeholder="+2348012345678"
                            defaultValue={profile.phoneNumber}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label className="form-label">Delivery address :</Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="package"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <textarea
                            id="deliveryAddress"
                            type="text"
                            className="form-control ps-5"
                            defaultValue={profile.deliveryAddress}
                            rows={3}
                            required
                          />
                        </div>
                      </Col>

                      <div className="col-lg-12 mt-2 mb-0">
                        <button className="btn btn-primary" onClick={updateProfile}>
                          Save Changes
                        </button>
                      </div>
                    </Row>
                  </Form>

                </TabPane>

                <TabPane
                  className="show fade bg-white shadow rounded p-4"
                  tabId="3"
                >
                  <Form>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">How many times in a month do you shop online</Label>
                            <select
                              className="form-select form-control"
                              id="monthlyShoppingFrequency"
                              defaultValue={profile.monthlyShoppingFrequency}
                            >
                              <option value={profile.monthlyShoppingFrequency}>{profile.monthlyShoppingFrequency}</option>
                              <option value={0}>0</option>
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                              <option value={5}>5</option>
                              <option value={6}>6</option>
                              <option value={7}>7</option>
                              <option value={8}>8</option>
                              <option value={9}>9</option>
                            </select>
                        </div>
                      </Col>
                      <Col md={6}>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <Label className="form-label">Select the type of products you buy online.</Label>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox checked={Gaming} onChange={handleChange} name="Gaming" />
                              }
                              label="Gaming"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox checked={Beauty} onChange={handleChange} name="Beauty" />
                              }
                              label="Beauty"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox checked={Health} onChange={handleChange} name="Health" />
                              }
                              label="Health"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox checked={WomenFashion} onChange={handleChange} name="Women's Fashion" />
                              }
                              label="Women's Fashion"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox checked={MensFashion} onChange={handleChange} name="Men's Fashion" />
                              }
                              label="Men's Fashion"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox checked={Accessories} onChange={handleChange} name="Accessories" />
                              }
                              label="Accessories"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox checked={GymFitness} onChange={handleChange} name="Gym and Fitness" />
                              }
                              label="Gym and Fitness"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox checked={Electronics} onChange={handleChange} name="Electronics" />
                              }
                              label="Electronics"
                            />
                          </FormGroup>
                        </FormControl>
                      </Col>
                      <Col xs={12}>
                      {shoppingInterests.map((category, index) => {
                        var products = [];
                        var productName = "";
                        var productPrice = "";
                        var productName2 = "";
                        var productPrice2 = "";
                        allProducts.forEach((product) => {
                          if (product.id === category){
                            products = product.products;
                            productName = products[0].name;
                            productPrice = products[0].price;
                            productName2 = products[1].name;
                            productPrice2 = products[1].price;
                          };
                        });
                        return (
                          <div>
                          <Label className="form-label">Describe a product you have bought from the following category: {category}</Label>
                          <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Product name</Label>
                              <div className="form-icon position-relative">
                                <FeatherIcon
                                  icon="package"
                                  className="fea icon-sm icons"
                                />
                                <input
                                  name="name"
                                  id={category+"ProductName"}
                                  type="text"
                                  className="form-control ps-5"
                                  placeholder="product name"
                                  defaultValue={productName}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Product price (NGN)</Label>
                              <div className="form-icon position-relative">
                                <FeatherIcon
                                  icon="dollar-sign"
                                  className="fea icon-sm icons"
                                />
                                <input
                                  name="price"
                                  id={category+"Price"}
                                  type="number"
                                  className="form-control ps-5"
                                  defaultValue={productPrice}
                                />
                              </div>
                            </div>
                          </Col>
                          </Row>
                          <Label className="form-label">Describe a product you would like to buy from the following category: {category}</Label>
                          <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Product name</Label>
                              <div className="form-icon position-relative">
                                <FeatherIcon
                                  icon="package"
                                  className="fea icon-sm icons"
                                />
                                <input
                                  name="name"
                                  id={category+"ProductName2"}
                                  type="text"
                                  className="form-control ps-5"
                                  placeholder="product name"
                                  defaultValue={productName2}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">Estimated product price (NGN)</Label>
                              <div className="form-icon position-relative">
                                <FeatherIcon
                                  icon="dollar-sign"
                                  className="fea icon-sm icons"
                                />
                                <input
                                  name="price"
                                  id={category+"Price2"}
                                  type="number"
                                  className="form-control ps-5"
                                  placeholder={10000}
                                  defaultValue={productPrice2}
                                />
                              </div>
                            </div>
                          </Col>
                          </Row>
                          </div>
                        );
                      })}
                      </Col>

                      <div className="col-lg-12 mt-2 mb-0">
                        <button className="btn btn-primary" onClick={updateInterests}>
                          Save Changes
                        </button>
                      </div>
                    </Row>
                  </Form>
                </TabPane>

                <TabPane
                  className="show fade bg-white shadow rounded p-4"
                  tabId="4"
                >
                  <div className="table-responsive bg-white shadow rounded">
                    <Table className="mb-0 table-center table-nowrap">
                      <thead>
                        <tr>
                          <th scope="col" className="border-bottom">Order id</th>
                          <th scope="col" className="border-bottom">Date</th>
                          <th scope="col" className="border-bottom">Status</th>
                          <th scope="col" className="border-bottom">Price</th>
                          <th scope="col" className="border-bottom">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders.map((order) => {
                          if (order.orderId !== "--stats--") {
                            var revenue = 0;
                            var productCost = 0;
                            var deliveryFee = 0;
                            var storeDeliveryFee = 0;
                            let date;
                            var status = "Pending confirmation"
                            order.products.forEach(product => {
                              if(product.dropshipping === true){
                                revenue = revenue + Number(product.price);
                                deliveryFee = deliveryFee + Number(product.deliveryFee);
                                productCost = productCost + Number(product.cost);
                              } else {
                                revenue = revenue + Number(product.cost);
                                storeDeliveryFee = Number(0);
                              };
                              if (product.status === "Confirmed and awaiting delivery") {
                                status = "Processing";
                              };
                              if (product.status === "Delivered") {
                                status = "Delivered";
                              };
                            });
                            date = order.timestamp.toDate().toDateString();
                            return (
                              <tr key={order.orderId} onClick={() => openModal(order)}>
                                <th component="th" scope="row">
                                  {order.orderId}
                                </th>
                                <td align="right">{date}</td>
                                {(status === "Pending confirmation") && (
                                  <td className="text-muted">{status}</td>
                                )}
                                {(status === "Processing") && (
                                  <td className="text-muted">{status}</td>
                                )}
                                {(status === "Delivered") && (
                                  <td className="text-success">{status}</td>
                                )}
                                <td align="right">NGN {revenue + storeDeliveryFee}</td>
                                <td>
                                  <Link to="#" className="text-primary">
                                    View <i className="uil uil-arrow-right"></i>
                                  </Link>
                                </td>
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </tbody>
                    </Table>
                  </div>
                </TabPane>

                <TabPane
                  className="show fade bg-white shadow rounded p-4"
                  tabId="5"
                >
                  <h6 className="text-muted mb-0">
                    The following are all the SWHAG stores you have visited.
                  </h6>

                  <Row>
                    {stores.map(store => {
                      return (
                        <Col lg={6} key={store.id} className="mt-4 pt-2 border-top">
                          <div className="d-flex align-items-center mb-4 justify-content-between">
                            <h5 className="mb-0">{store.storeName}</h5>
                          </div>
                          <div>
                          <a target="_blank" href={"https://myswhag.com/"+store.storeName} rel="noreferrer">Visit website</a>
                          </div>
                        </Col>
                      );
                    })}

                  </Row>
                </TabPane>

              </TabContent>
            </Col>
            <Snackbar open={isSnackbarSaveOpen} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Your changes have been saved successfully!
              </Alert>
            </Snackbar>
            <Modal isOpen={isModalOpen} toggle={closeModal} centered size="lg">
              <ModalHeader toggle={closeModal}>Products</ModalHeader>
              <div onClick={closeModal} className="btn btn-soft-primary ms-2">
                Close
              </div>
              <ModalBody className="p-4">
              <h5 className="mt-4">Customer details :</h5>
              <p className="text-muted">Name: {singleOrder.customerFirstName} {singleOrder.customerLastName}</p>
              <p className="text-muted">Email: {singleOrder.customerEmail} </p>
              <p className="text-muted">PhoneNumber: {singleOrder.customerPhoneNumber} </p>
              <p className="text-muted">Delivery Address: {singleOrder.deliveryAddress} </p>
              <p className="text-muted">Date: {timestamp} </p>

              {products.map((product, key) =>  {
                if (product.dropshipping === true) {
                  return (
                    <Container fluid className="px-0">
                    <Row>
                      <Col lg={5}>
                        <div className="tiny-single-item">
                          <div className="tiny-slide"><img src={product.picUrl} className="img-fluid rounded" alt="" /></div>
                        </div>
                      </Col>

                      <Col lg={7} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <h4 className="title">{product.name}</h4>
                        <p className="text-muted">Store Email: {product.merchantEmail} </p>
                        <p className="text-muted">Contact merchant for shipping updates </p>
                        <h5 className="mt-4">Price:</h5>
                        <h5 className="text-muted">NGN {product.price}</h5>
                        <h5 className="mt-4">Quantity:</h5>
                        <h5 className="text-muted">{product.items}</h5>
                        {product.multipleSizes &&(
                          <div>
                            <h5 className="mt-4">Variant:</h5>
                            <h5 className="text-muted">{product.size}</h5>
                          </div>
                        )}
                        <h5 className="mt-4">Status:</h5>
                        <h5 className="text-muted">{product.status}</h5>
                      </Col>
                    </Row>
                  </Container>
                  );
                } else {
                  return (
                    <Container fluid className="px-0">
                    <Row>
                      <Col lg={5}>
                        <div className="tiny-single-item">
                          <div className="tiny-slide"><img src={product.picUrl} className="img-fluid rounded" alt="" /></div>
                        </div>
                      </Col>

                      <Col lg={7} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <h4 className="title">{product.name}</h4>
                        <h5 className="mt-4">Price:</h5>
                        <h5 className="text-muted">NGN {product.cost}</h5>
                        <h5 className="mt-4">Quantity:</h5>
                        <h5 className="text-muted">{product.items}</h5>
                        {product.multipleSizes &&(
                          <div>
                            <h5 className="mt-4">Variant:</h5>
                            <h5 className="text-muted">{product.size}</h5>
                          </div>
                        )}
                        <h5 className="mt-4">Status:</h5>
                        <h5 className="text-muted">{product.status}</h5>
                      </Col>
                    </Row>
                  </Container>
                );
                };
                })}
                
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Profile;
