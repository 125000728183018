import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database'
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/functions';
// Your web app's Firebase configuration

var firebaseConfig = {
  apiKey: "AIzaSyC2G0eWDN1HH-g4eFu-OEm-BjzDODTq-r8",
  authDomain: "swhag-shoppers.firebaseapp.com",
  projectId: "swhag-shoppers",
  storageBucket: "swhag-shoppers.appspot.com",
  messagingSenderId: "807747194259",
  appId: "1:807747194259:web:67e152bc26b8e65267ac66",
  measurementId: "G-SSHZ613G75"
};

var secondaryAppConfig = {
  apiKey: "AIzaSyBilc-DSpa8kVrlXhDaH0ZLGoRRfM8PVMY",
  authDomain: "swhag-1a9e3.firebaseapp.com",
  projectId: "swhag-1a9e3",
  storageBucket: "swhag-1a9e3.appspot.com",
  messagingSenderId: "935468590993",
  appId: "1:935468590993:web:9bed76073b2acd3c2e5c30",
  measurementId: "G-4DJ70B42XC"
};
// Initialize Firebase

export const fire = firebase.initializeApp(firebaseConfig);
export const fire2 = firebase.initializeApp(secondaryAppConfig, "secondary");
firebase.analytics();

