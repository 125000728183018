import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { green } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import FeatherIcon from "feather-icons-react";
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

import { ConfirmProvider } from 'material-ui-confirm';
import { useConfirm } from 'material-ui-confirm';
import {fire} from "../Fire.js";
import firebase from 'firebase';

function useStore() {
  const [store, setStore] = React.useState({});

  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        setStore(Object.assign(doc.data(), {storeId: doc.id}));
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return store;
};

function useProducts() {
  const [products, setProducts] = React.useState([]);
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const id = [];
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(queryDocumentSnapshot => {
        var documentId = queryDocumentSnapshot.id;
        var storeInfo = queryDocumentSnapshot.data();
        id.push(documentId);
        id.forEach((item) => {
          fire
          .firestore()
          .collection("users")
          .doc(user.uid)
          .collection("stores")
          .doc(item)
          .collection("products")
          .onSnapshot(queryDocumentSnapshot => {
            setProducts([]);
            queryDocumentSnapshot.forEach(eachDocument => {
              const doc = eachDocument.data();
              setProducts(currentProducts => [...currentProducts, Object.assign(doc, {storeName: storeInfo.storeName, storeId: documentId, docId: eachDocument.id,})]);
            });
          });
        });
      });
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return products;
};

const MyProducts = ({profile}) => {
  const user = fire.auth().currentUser;
  const store = useStore();
  const confirm = useConfirm();
  let history = useHistory();
  const products = useProducts();
  const [isModalOpen, setOpenModal] = React.useState(false);
  const [product, setProduct] = React.useState({});
  const [isOpenNewProduct, setOpenNewProduct] = React.useState(false);
  const [isMultipleSizes, setIsMultipleSizes] = React.useState(false);
  const [isOnSale, setOnSale] = React.useState(false);
  const [shippingLocation, setLocation] = React.useState("Nigeria");
  const [productId, setProductId] = React.useState();

  let addMultipleSizes;
  let addShippingTime;
  let editProduct;
  let addSalePrice;

  const openNewProduct = (e) => {
    setOpenNewProduct(true);
  };

  const handleChecked = (e) => {

    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .collection("products")
    .doc(e.docId)
    .update({
      published: !e.published,
    });
    fire
    .firestore()
    .collection("products")
    .doc(e.productId)
    .set(e);

    fire
    .firestore()
    .collection("products")
    .doc(e.productId)
    .update({
      published: !e.published,
    });
  };
  const openModal = (e) => {
    setOpenModal(true);
    setProduct(e);
  };
  const closeModal = () => {
    setOpenModal(false);
    setOpenNewProduct(false);
    setOnSale(false);
  };

  const changeOnSale = () => {
    const onSale = Boolean(document.getElementById("onSale").value);
    setOnSale(onSale);
  };
  const changeProductVariants = (e) => {
    const multipleSizes = Boolean(document.getElementById("oneSize").value);
    setIsMultipleSizes(multipleSizes);
  };
  const changeLocation = (e) => {
    const location = document.getElementById("location").value;
    setLocation(location);
  };
  if(isMultipleSizes === true){
    addMultipleSizes = (
      <Col md={6}>
        <div className="mb-3">
          <Label className="form-label">
            Enter the different product sizes you have separate them with a comma <span className="text-danger">*</span>
          </Label>
          <Input
            name="name"
            id="variants"
            type="text"
            className="form-control ps-5"
            placeholder="e.g 'XS,S,M,L,XL,2XL'"
          />
        </div>
      </Col>
    );
  } else {
    addMultipleSizes = (
      <div></div>
    );
  };
  if(shippingLocation === "Nigeria"){
    addShippingTime = (
      <div></div>
    );
  } else {
    addShippingTime = (
      <Col md={6}>
        <div className="mb-3">
          <Label className="form-label">
            Enter how many days it will take to deliver to Nigeria <span className="text-danger">*</span>
          </Label>
          <Input
            id="shippingDays"
            type="number"
            className="form-control ps-5"
            placeholder="7"
          />
        </div>
      </Col>
    );
  };
  if(isOnSale === true){
    addSalePrice = (
      <Col md={6}>
        <div className="mb-3">
          <Label className="form-label">
            Enter the old price of the product <span className="text-danger">*</span>
          </Label>
          <Input
            id="oldPrice"
            type="number"
            className="form-control ps-5"
            defaultValue={product.oldPrice}
          />
        </div>
      </Col>
    );
  } else {
    addSalePrice = (
      <div></div>
    );
  };

  const newProductId = (e) => {
    setProductId(e.productId);
  };

  const selectPic = (e) => {
    const image = e.target.files[0];
    const productDocument = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc(productId);
    const storageRef = fire.storage().ref('users/'+user.uid+'/stores/'+store.storeId+'/products/'+productId);
    const fileRef = storageRef.child('productImage');
    fileRef.put(image).then((e) => {
      fileRef.getDownloadURL().then((url) => {
        productDocument
        .update({
          picUrl: url,
        });
        productDocument
        .get()
        .then(doc => {
          if( (profile.verified === true) && (doc.data().published === true)) {
            fire.firestore().collection("products").doc(productId).update({
              picUrl: url,
            });
          };
        });
      });
    });
  };

  const update = (e) => {
    if(e.dropshipping === true) {
      const price = document.getElementById("price").value;
      const description = document.getElementById("description").value;
      fire
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("stores")
      .doc(e.storeId)
      .collection("products")
      .doc(e.docId)
      .update({
        price: Number(price),
        description: description,
      });
      setOpenModal(false);
    } else {
      const name = document.getElementById("name").value;
      const cost = document.getElementById("cost").value;
      const deliveryFee = document.getElementById("deliveryFee").value;
      const quantity = document.getElementById("quantity").value;
      const description = document.getElementById("description").value;

      if(e.multipleSizes === true) {
        const variants = document.getElementById("variants").value;
        fire
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("stores")
        .doc(store.storeId)
        .collection("products")
        .doc(e.docId)
        .update({
          name: name,
          price: Number(cost),
          cost: Number(cost),
          deliveryFee: Number(deliveryFee),
          quantity: Number(quantity),
          description: description,
          variants: variants.split(","),
        });
        if(e.published === true && profile.verified === true) {
          fire
          .firestore()
          .collection("products")
          .doc(e.productId)
          .update({
            name: name,
            price: Number(cost),
            cost: Number(cost),
            deliveryFee: Number(deliveryFee),
            quantity: Number(quantity),
            description: description,
            variants: variants.split(","),
            useruid: user.uid,
            storeId: store.storeId,
          });
        };
      } else {
        fire
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("stores")
        .doc(store.storeId)
        .collection("products")
        .doc(e.docId)
        .update({
          name: name,
          price: Number(cost), 
          cost: Number(cost),
          deliveryFee: Number(deliveryFee),
          quantity: Number(quantity),
          description: description,
        });
        if(e.published === true && profile.verified === true) {
          fire
          .firestore()
          .collection("products")
          .doc(e.productId)
          .update({
            name: name,
            price: Number(cost),
            cost: Number(cost),
            deliveryFee: Number(deliveryFee),
            quantity: Number(quantity),
            description: description,
            useruid: user.uid,
            storeId: store.storeId,
          });
        };
      };
      if (isOnSale === true){
        const oldPrice = document.getElementById("oldPrice").value;
        fire
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("stores")
        .doc(store.storeId)
        .collection("products")
        .doc(e.docId)
        .update({
          oldPrice: Number(oldPrice),
          onSale: isOnSale,
        });
        if(e.published === true && profile.verified === true) {
          fire
          .firestore()
          .collection("products")
          .doc(e.productId)
          .update({
            oldPrice: Number(oldPrice),
            onSale: isOnSale,
          });
        };
      };
      setOpenModal(false);
    };
    if (store.collections) {
      const collection = document.getElementById("collection").value;
      fire
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("stores")
      .doc(store.storeId)
      .collection("products")
      .doc(e.docId)
      .update({
        collection: collection,
      });
    };
  };

  if (product.dropshipping === true) {
    editProduct=(
      <div>
        <ModalHeader toggle={closeModal}>{product.name}</ModalHeader>
        <ModalBody className="p-4">
          <Container fluid className="px-0">        
            <Row>
              <Col lg={5}>
                <div className="tiny-single-item">
                  <div className="tiny-slide"><img src={product.picUrl} className="img-fluid rounded" alt="" /></div>
                </div>
              </Col>

              <Col lg={7} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                <h4 className="title">{product.name}</h4>
                <h5 className="mt-4">Costs:</h5>
                <h5 className="text-muted">NGN {product.cost}</h5>
                <h5 className="mt-4">Delivery Fee:</h5>
                <h5 className="text-muted">NGN {product.deliveryFee}</h5>

                {(store.collections) && (
                  <div className="mb-3">
                    <Label className="form-label">
                      Add this product to a collection?
                    </Label>
                    <select className="btn btn-icon form-control ps-5 quantity" id="collection" defaultValue={product.collection} style={{width: "100%"}}>
                      {store.collections.map((col, index) => {
                        return (
                          <option key={index} value={col.name}>{col.name}</option>
                        );
                      })}
                    </select>
                  </div>
                )}

                <h5 className="mt-4">Overview :</h5>
                <textarea name="description" id="description" defaultValue={product.description} type="text" rows="5"  className="btn btn-icon btn-soft-primary quantity" style={{width: "100%", height: 150}} />
                <div className="row mt-4 pt-2">

                  <div className="col-12 mt-4">
                    <div className="d-flex shop-list align-items-center">
                      <h6 className="mb-0">Set your price:</h6>
                      <div className="ms-3">
                        <div className="qty-icons">
                          NGN{"  "}<input min="0" name="price" id="price" defaultValue={product.price} type="number" className="btn btn-icon btn-soft-primary quantity" style={{width: 100}} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-4 pt-2">
                  <Link to="#" className="btn btn-primary" onClick={() =>update(product)} >Update</Link>
                  <div onClick={closeModal} className="btn btn-soft-primary ms-2">
                    Close
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </div>
    );
  } else {
    editProduct=(
      <div>
        <ModalHeader toggle={closeModal}>{product.name}</ModalHeader>
        <ModalBody className="p-4">
          <Container fluid className="px-0">
            <Row>
              <h4 className="card-title">Edit your Product!</h4>
              <Col lg={5}>
                <div className="tiny-single-item">
                  <div className="tiny-slide"><img src={product.picUrl} className="img-fluid rounded" alt="" /></div>
                </div>
              </Col>
              <div className="custom-form mt-3">
                <div id="message"></div>
                <Form
                  name="contact-form"
                  id="contact-form"
                >
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Your Product Name <span className="text-danger">*</span>
                        </Label>
                        <div className="form-icon position-relative">
                          <i>
                            <FeatherIcon
                              icon="package"
                              className="fea icon-sm icons"
                            />
                          </i>
                        </div>
                        <Input
                          name="name"
                          id="name"
                          type="text"
                          className="form-control ps-5"
                          placeholder="Product Name:"
                          defaultValue={product.name}
                          required
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Product price <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="price"
                          id="cost"
                          type="number"
                          className="form-control ps-5"
                          placeholder="5000"
                          defaultValue={product.cost}
                          required
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Delivery Fee <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="price"
                          id="deliveryFee"
                          type="number"
                          className="form-control ps-5"
                          placeholder="000"
                          defaultValue={product.deliveryFee}
                          required
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Quantity <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="quantity"
                          id="quantity"
                          type="number"
                          className="form-control ps-5"
                          placeholder="100"
                          defaultValue={product.quantity}
                          required
                        />
                      </div>
                    </Col>
                    {product.multipleSizes && (
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Enter the different product sizes you have separate them with a comma <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="name"
                            id="variants"
                            type="text"
                            className="form-control ps-5"
                            defaultValue={product.variants}
                          />
                        </div>
                      </Col>
                    )}
                    {(store.collections) && (
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Add this product to a collection? <span className="text-danger">*</span>
                          </Label>
                          <select className="btn btn-icon form-control ps-5 quantity" id="collection" defaultValue={product.collection} style={{width: "100%"}}>
                            {store.collections.map((col, index) => {
                              return (
                                <option key={index} value={col.name}>{col.name}</option>
                              );
                            })}
                          </select>
                        </div>
                      </Col>
                    )}
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Is this product on sale? <span className="text-danger">*</span>
                        </Label>
                        <select className="btn btn-icon form-control ps-5 quantity" id="onSale" onChange={changeOnSale} style={{width: "100%"}}>
                          <option key={0} value={""}>No</option>
                          <option key={1} value={true}>Yes</option>
                        </select>
                      </div>
                    </Col>
                    {addSalePrice}
                    <Col md={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Description{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <div className="form-icon position-relative">
                          <i>
                            <FeatherIcon
                              icon="message-square"
                              className="fea icon-sm icons"
                            />
                          </i>
                        </div>
                        <textarea
                          name="description"
                          id="description"
                          type="text"
                          className="form-control ps-5"
                          placeholder="Describe the product you are selling:"
                          rows={5}
                          defaultValue={product.description}
                          required
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} className="text-center">
                      <div className="d-grid">
                        <input
                          id="submit"
                          name="send"
                          className="submitBnt btn btn-primary"
                          value="Update product"
                          onClick={() =>update(product)}
                        />
                      </div>
                      <div id="simple-msg"></div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Row>
          </Container>
        </ModalBody>
      </div>
    );
  };

  const createProduct = (e) => {
    e.preventDefault();
    const name = document.getElementById("name").value;
    const cost = document.getElementById("cost").value;
    const deliveryFee = document.getElementById("deliveryFee").value;
    const quantity = document.getElementById("quantity").value;
    const multipleSizes = Boolean(document.getElementById("oneSize").value);
    const description = document.getElementById("description").value;
    const category = document.getElementById("category").value;
    const location = document.getElementById("location").value;

    if(multipleSizes === true) {
      const variants = document.getElementById("variants").value;
      const productRef = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc();
      if(location === "Nigeria") {
        productRef.set({useruid: user.uid, email: user.email, merchantEmail: user.email, merchantFirstName: profile.firstName, merchantLastName: profile.lastName, subaccount: store.subaccount, storeId: store.storeId, ownedByStore: true, productId: productRef.id, name: name, price: Number(cost), cost: Number(cost), quantity: Number(quantity), multipleSizes: multipleSizes, description: description, deliveryFee: Number(deliveryFee), category: category, location: location, published: false, isMerch: false, variants: variants.split(","), sales: 0, dateCreated: firebase.firestore.FieldValue.serverTimestamp(), picUrl: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/products%2F--default--%2Fdefault-product.jpg?alt=media&token=e5702835-a4b6-4131-a789-5d0207286b42"});
        setOpenNewProduct(false);
      } else {
        const shippingDays = document.getElementById("shippingDays").value;
        productRef.set({useruid: user.uid, email: user.email, merchantFirstName: profile.firstName, merchantLastName: profile.lastName, subaccount: store.subaccount, storeId: store.storeId, ownedByStore: true, productId: productRef.id, name: name, price: Number(cost), cost: Number(cost), quantity: Number(quantity), multipleSizes: multipleSizes, description: description, deliveryFee: Number(deliveryFee), category: category, location: location, shippingDays: shippingDays, published: false, isMerch: false, variants: variants.split(","), sales: 0, dateCreated: firebase.firestore.FieldValue.serverTimestamp(), picUrl: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/products%2F--default--%2Fdefault-product.jpg?alt=media&token=e5702835-a4b6-4131-a789-5d0207286b42"});
        
        setOpenNewProduct(false);
      }
    } else {
      const productRef = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc();
      if(location === "Nigeria") {
        productRef.set({useruid: user.uid, email: user.email, merchantFirstName: profile.firstName, merchantLastName: profile.lastName, subaccount: store.subaccount, storeId: store.storeId, ownedByStore: true, productId: productRef.id, name: name, price: Number(cost), cost: Number(cost), quantity: Number(quantity), multipleSizes: multipleSizes, description: description, deliveryFee: Number(deliveryFee), category: category, location: location, published: false, isMerch: false, sales: 0, dateCreated: firebase.firestore.FieldValue.serverTimestamp(), picUrl: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/products%2F--default--%2Fdefault-product.jpg?alt=media&token=e5702835-a4b6-4131-a789-5d0207286b42"});
        setOpenNewProduct(false);
      } else {
        const shippingDays = document.getElementById("shippingDays").value;
        productRef.set({useruid: user.uid, email: user.email, merchantFirstName: profile.firstName, merchantLastName: profile.lastName, subaccount: store.subaccount, storeId: store.storeId, ownedByStore: true, productId: productRef.id, name: name, price: Number(cost), cost: Number(cost), quantity: Number(quantity), multipleSizes: multipleSizes, description: description, deliveryFee: Number(deliveryFee), category: category, location: location, shippingDays: shippingDays, published: false, isMerch: false, sales: 0, dateCreated: firebase.firestore.FieldValue.serverTimestamp(), picUrl: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/products%2F--default--%2Fdefault-product.jpg?alt=media&token=e5702835-a4b6-4131-a789-5d0207286b42"});
        setOpenNewProduct(false);
      };
    };

  };

  const removeProduct = (e) => {
    confirm({ description: 'Are you sure you want to remove '+e.name+' from your store?', confirmationText: 'Yes' })
      .then(() => { 
        console.log("yes it worked") 
        if (e.dropshipping === true){
          fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc(e.docId).delete();
        } else {
          fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc(e.docId).delete();
          if (e.published === true) {
            const deleteMerchantProduct = fire.functions().httpsCallable('deleteMerchantProduct');
            deleteMerchantProduct({productId: e.productId}).then(result => {
              console.log(result.data);
            });
          };
        }
      })
      .catch(() => { /* ... */ });
  };

  const checkQuantity = () => {
    products.forEach(product => {
      if (product.dropshipping === true){
        fire.firestore().collection("products").doc(product.productId).get().then(doc => {
          const originalProduct = doc.data();
          const totalCosts = Number(originalProduct.cost) + Number(originalProduct.deliveryFee);
          if (originalProduct.published === true){
            fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc(product.docId).update({
              quantity: Number(originalProduct.quantity),
              deliveryFee: Number(originalProduct.deliveryFee),
              cost: Number(originalProduct.cost),
            });
            if (product.price <= totalCosts){
              fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc(product.docId).update({
                price: totalCosts*1.2,
              });
            };
            if (originalProduct.multipleSizes === true){
              fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc(product.docId).update({
                variants: originalProduct.variants,
              });
            };
          } else {
            fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("products").doc(product.docId).update({
              quantity: 0,
            });
          };
        });
      };
    });
  };

  return (
    <ConfirmProvider>
    <React.Fragment>
      {/* breadcrumb */}
      <Container>
        <Row>
          <Col key={1} md={6} xs={12} className="mt-4 pt-2" style={{display: 'flex', justifyContent: 'center',alignItems:'center'}}>
            <Button onClick={openNewProduct} variant="contained" style={{ backgroundColor: green[500], color: "#ffffff"}}>
              Create your own product
            </Button>
          </Col>
          <Col key={2} md={6} xs={12} className="mt-4 pt-2" style={{display: 'flex', justifyContent: 'center',alignItems:'center'}}>
            <Button onClick={() => history.push("/")} variant="contained" style={{ backgroundColor: green[500], color: "#ffffff"}}>
              Add a product from SWHAG
            </Button>
          </Col>
          <Col key={3} xs={12} className="mt-4 pt-2" style={{display: 'flex', justifyContent: 'center',alignItems:'center'}}>
            <Button onClick={checkQuantity} variant="contained" style={{ backgroundColor: green[500], color: "#ffffff"}}>
              Check if Dropshipping products are in stock
            </Button>
          </Col>
          {(profile.verified !== true) && (
            <Col key={4} xs={12} className="mt-4 pt-2" style={{display: 'flex', justifyContent: 'center',alignItems:'center'}}>
              <Button onClick={() => history.push("/Settings")} variant="contained" style={{ backgroundColor: green[500], color: "#ffffff"}}>
                Request to publish your products on SWHAG
              </Button>
            </Col>
          )}
          {products.map((product, key) => {
            let sale;
              if (product.onSale === true){
                var difference = (product.oldPrice - product.price);
                sale = Math.round((difference/product.oldPrice)*100);
              };
            return (
            <Col key={key} lg={4} md={6} xs={12} className="mt-4 pt-2">
              <Card className="shop-list border-0 position-relative">
                <ul className="label list-unstyled mb-0">
                  {(product.onSale === true) && (
                    <li>
                      <div
                        className="badge badge-link rounded-pill bg-primary"
                      >
                        {sale}% OFF
                      </div>
                    </li>
                  )}
                  {(product.dropshipping === true) && (
                    <li>
                      <Link
                        to="#"
                        className="badge badge-link rounded-pill bg-primary"
                      >
                        Dropshipping product
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link
                      to="#"
                      className="badge badge-link rounded-pill bg-success"
                    >
                      Quantity: {product.quantity}
                    </Link>
                  </li>
                  {(product.quantity <= 0) && (
                    <li>
                    <Link
                      to="#"
                      className="badge badge-link rounded-pill bg-warning"
                    >
                      Out of Stock!!
                    </Link>
                  </li>
                  )}
                </ul>
                <div className="shop-image position-relative overflow-hidden rounded shadow">
                  <Link to="#" onClick={() => openModal(product)}>
                    <img
                      src={product.picUrl}
                      className="img-fluid"
                      alt="S.W.H.A.G"
                    />
                  </Link>
                  <Link
                    to="#"
                    onClick={() => openModal(product)}
                    className="overlay-work"
                  >
                    <img
                      src={product.picUrl}
                      className="img-fluid"
                      alt="S.W.H.A.G"
                    />
                  </Link>
                </div>
                <CardBody className="content pt-4 p-2">
                  {(product.dropshipping !== true) && (
                    <InputLabel style={{display: 'flex', justifyContent: 'center',alignItems:'center'}}>
                      <Input type="file" onChange={selectPic} onClick={() => newProductId(product)} style={{display: 'none'}} />
                      <Typography color="primary">Change product image</Typography>
                    </InputLabel>
                  )}
                  <div className="d-flex justify-content-between mt-1">
                    <Link
                      to="#"
                      onClick={() => openModal(product)}
                      className="text-dark product-name h6"
                    >
                      {product.name}
                    </Link>
                    {(product.dropshipping !== true && profile.verified === true) && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={product.published}
                          onChange={() => handleChecked(product)}
                          name="published"
                        />
                      }
                      label="Publish"
                    />
                    )}
                  </div>
                  <div className="d-flex justify-content-between mt-1">
                    <h6 className="text-muted small fst-italic mb-0 mt-1">
                      NGN {(product.dropshipping === true) && (product.price)}{(product.dropshipping !== true) && (product.cost)}{" "}
                    </h6>
                    <Link
                      to="#"
                      onClick={() => openModal(product)}
                      className="text-dark product-name h6"
                    >
                      <h6 className="text-muted small fst-italic mb-0 mt-1">
                        Edit Product
                      </h6>
                    </Link>
                  </div>
                  <Link
                    to="#"
                    onClick={() => removeProduct(product)}
                    className="text-dark product-name h6"
                  >
                    <h6 className=" small fst-italic mb-0 mt-1" style={{color: 'red'}}>
                      Remove Product
                    </h6>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            );
          })}

          <Modal isOpen={isModalOpen} toggle={closeModal} centered size="lg">
            {editProduct}
          </Modal>
          <Modal isOpen={isOpenNewProduct} toggle={closeModal} centered size="lg">
            <ModalHeader toggle={closeModal}>{product.name}</ModalHeader>

            <ModalBody className="p-4">
              <Container fluid className="px-0">
                <Row>
                  <h4 className="card-title">Create your Product!</h4>
                  <div className="custom-form mt-3">
                    <div id="message"></div>
                    <Form
                      method="post"
                      onSubmit={createProduct}
                      name="contact-form"
                      id="contact-form"
                    >
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Your Product Name <span className="text-danger">*</span>
                            </Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="package"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <Input
                              name="name"
                              id="name"
                              type="text"
                              className="form-control ps-5"
                              placeholder="Product Name:"
                              required
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Product price <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="price"
                              id="cost"
                              type="number"
                              className="form-control ps-5"
                              placeholder="5000"
                              defaultValue={5000}
                              required
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Delivery Fee <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="price"
                              id="deliveryFee"
                              type="number"
                              className="form-control ps-5"
                              placeholder="000"
                              defaultValue={1000}
                              required
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Quantity <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="quantity"
                              id="quantity"
                              type="number"
                              className="form-control ps-5"
                              placeholder="100"
                              required
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              What category is your product in? <span className="text-danger">*</span>
                            </Label>
                            <select className="btn btn-icon form-control ps-5 quantity" id="category" style={{width: "100%"}}>
                              <option value={"Gaming"}>Gaming</option>
                              <option value={"Beauty"}>Beauty</option>
                              <option value={"Health"}>Health</option>
                              <option value={"Women's Fashion"}>Women's Fashion</option>
                              <option value={"Men's Fashion"}>Men's Fashion</option>
                              <option value={"Accessories"}>Accessories</option>
                              <option value={"Gym and Fitness"}>Gym and Fitness</option>
                              <option value={"Electronics"}>Electronics</option>
                              <option value={"Others"}>Others</option>
                            </select>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Does your product have multiple sizes? <span className="text-danger">*</span>
                            </Label>
                            <select className="btn btn-icon form-control ps-5 quantity" id="oneSize" onChange={changeProductVariants} style={{width: "100%"}}>
                              <option key={0} value={""}>No</option>
                              <option key={1} value={true}>Yes</option>
                            </select>
                          </div>
                        </Col>
                        {addMultipleSizes}
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Where will you ship this product from when you recieve an order? <span className="text-danger">*</span>
                            </Label>
                            <select className="btn btn-icon form-control ps-5 quantity" id="location" onChange={changeLocation} style={{width: "100%"}}>
                              <option value={"Nigeria"}>Nigeria</option>
                              <option value={"Abroad"}>Abroad</option>
                            </select>
                          </div>
                        </Col>
                        {addShippingTime}
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Description{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="message-square"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <textarea
                              name="description"
                              id="description"
                              type="text"
                              className="form-control ps-5"
                              placeholder="Describe the product you are selling:"
                              rows={5}
                              required
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} className="text-center">
                          <div className="d-grid">
                            <input
                              type="submit"
                              id="submit"
                              name="send"
                              className="submitBnt btn btn-primary"
                              value="Create product"
                            />
                          </div>
                          <div id="simple-msg"></div>
                          <div onClick={closeModal} className="btn btn-soft-primary ms-2">
                            Close
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
        </Row>
      </Container>
    </React.Fragment>
    </ConfirmProvider>
  );
};


export default MyProducts;
