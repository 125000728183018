import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { Link } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

//Import Icons
import FeatherIcon from "feather-icons-react";

import { useHistory } from "react-router-dom";
import {fire} from "../Fire.js";
import MyPagination from "./MyPagination.js";
import Pricing from "./Pricing.js";
import { PaystackButton } from 'react-paystack';
import firebase from 'firebase';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function useProfile() {
  const [profile, setProfile] = React.useState({});

  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .onSnapshot(document => {
      setProfile(document.data());
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return profile;
};

function useProducts() {
  const [products, setProducts] = React.useState([]);


  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const id = [];
    const unsubscribe = fire
    .firestore()
    .collection("products")
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(queryDocumentSnapshot => {
        var documentId = queryDocumentSnapshot.id;
        id.push(documentId);
        setProducts( currentProducts => [...currentProducts, Object.assign(queryDocumentSnapshot.data(), {productId: documentId})]);
      });
      // id.forEach((item) => {
      //   fire
      //   .firestore()
      //   .collection("products")
      //   .doc(item)
      //   .get()
      //   .then(snap => {
      //     const doc = snap.data();
      //     setProducts( currentProducts => [...currentProducts, Object.assign(doc, {productId: snap.id})]);
      //   });
      // });
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return products;
};

function useStores() {
  const [stores, setStores] = React.useState([]);


  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(queryDocumentSnapshot => {
        setStores(currentStores => [...currentStores, Object.assign(queryDocumentSnapshot.data(), {id: queryDocumentSnapshot.id})]);
      });
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return stores;
};

function useStore() {
  const [store, setStore] = React.useState({});
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        setStore(Object.assign(doc.data(), {storeId: doc.id,}));
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return store;
};

const ProductList = props => {
  const user = fire.auth().currentUser;
  let history = useHistory();
  const profile = useProfile();
  const products = useProducts();
  const stores = useStores();
  const store = useStore();
  const [product, setProduct] = React.useState({});
  const [isModalOpen, setOpenModal] = React.useState(false);
  const [isPricingModalOpen, setPricingModalOpen] = React.useState(false);
  const [isRequestModalOpen, setRequestOpenModal] = React.useState(false);
  const [isSnackbarOpen, setOpenSnackbar] = React.useState(false);
  const [searchField, setSearchField] = React.useState("");
  const [myFilter, setMyFilter] = React.useState("All");

  const publicKey = "pk_live_9296b30945eba87a279e3d7ca33cefb6fc2fa261";
  const [plan, setPlanCode] = React.useState(null);
  const [planTitle, setPlanTitle] = React.useState(null);
  const [planPrice, setPlanPrice] = React.useState(null);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [productsPerPage, setProductsPerPage] = React.useState(20);

  const requestProduct = () => {
    const firstName = document.getElementById("name").value;
    const price = document.getElementById("price").value;
    const product = document.getElementById("productName").value;
    const newProduct = fire.functions().httpsCallable('newProduct');
    newProduct({ firstName: firstName, price: price, email: user.email, product: product }).then(res => {
      console.log(res.data);
    });
    setOpenSnackbar(true);
    setRequestOpenModal(false);
  };
  const openRequestModal = () => {
    setRequestOpenModal(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const changePlan = (plan, title, amount) => {
    setPlanCode(plan);
    setPlanPrice(amount);
    setPlanTitle(title);
    setPricingModalOpen(false);
  };

  const componentProps = {
    email: user.email,
    publicKey,
    plan,
    onSuccess: () => {
      fire.firestore().collection("users").doc(user.uid).update({
        subscribed: true,
        plan: planTitle,
        planPrice: planPrice,
        dateSubscribed: firebase.firestore.FieldValue.serverTimestamp(),
      });
      setPlanCode(null);
    },
    onClose: () => null,
  };
  const componentProps2 = {
    email: user.email,
    publicKey,
    amount: plan,
    onSuccess: () => {
      fire.firestore().collection("users").doc(user.uid).update({
        subscribed: true,
        plan: planTitle,
        planPrice: planPrice,
        dateSubscribed: firebase.firestore.FieldValue.serverTimestamp(),
      });
      setPlanCode(null);
    },
    onClose: () => null,
  };
  let payButton;

  if (plan !== null) {
    if (plan === 199900) {
      payButton = (
        <PaystackButton {...componentProps2} className="btn btn-primary">
          Pay one-off for {planTitle} plan
        </PaystackButton>
      )
    } else {
      payButton = (
        <PaystackButton {...componentProps} className="btn btn-primary">
          Subscribe to {planTitle} plan
        </PaystackButton>
      )
    };
  };

  const search = (products) => {
    const x = products.filter(
      (product) => 
      (
        product.name.toLowerCase().indexOf(searchField) > -1 &&
        (product.category === myFilter || myFilter === "All") && 
        product.published === true
      )
    );
    return x;
  };

  const changeFilter = () => {
    const category = document.getElementById("category").value;
    setMyFilter(category);
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const curentProducts = search(products).slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= Math.ceil(products.length / productsPerPage)){
      setCurrentPage(pageNumber);
    } 
  };

  const openModal = (e) => {
    if(profile.store === true) {
      setOpenModal(true);
      setProduct(e);
    } else {
      history.push("/Store");
    };
  };

  const closeModal = () => {
    setOpenModal(false);
    setRequestOpenModal(false);
    setPricingModalOpen(false);
  };

  const addProduct = (e) => {
    const storeId = document.getElementById("store").value;
    const price = document.getElementById("price").value;
    const obj = Object.assign(e, {price: parseFloat(price), published: false, dropshipping: true});

    
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(storeId)
    .collection("products")
    .doc(e.productId)
    .set(obj);

    fire
    .firestore()
    .collection("users")
    .doc(e.useruid)
    .collection("stores")
    .doc(e.storeId)
    .collection("products")
    .doc(e.productId)
    .collection("stores")
    .doc(storeId)
    .set({
      storeId: storeId,
      userUid: user.uid,
      storeEmail: user.email
    });

    if (store.collections) {
      const collection = document.getElementById("collection").value;
      fire
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("stores")
      .doc(storeId)
      .collection("products")
      .doc(e.productId)
      .update({
        collection: collection,
      });
    };

    setOpenModal(false);
    setProduct({});
  };

  return (
    <React.Fragment>
      {/* breadcrumb */}
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section" style={{width: "100%"}}>
        <Container>
          <Row>
            <Col lg={3} md={4} xs={12}>
              <Card className="border-0 sidebar sticky-bar">
                <CardBody className="p-0">
                  <Link to="#" className="btn btn-primary mt-4" onClick={() => history.push('/Store')}>
                    Create your own store
                  </Link>
                  <Link to="#" className="btn btn-primary mt-4" onClick={() => history.push('/Settings')}>
                    Become a third party merchant
                  </Link>
                  <Link to="#" className="btn btn-primary mt-4" onClick={openRequestModal}>
                    Request for a product
                  </Link>
                  <div className="widget">
                    <div id="search2" className="widget-search mb-0">
                      <Form
                        role="search"
                        method="get"
                        id="searchform"
                      >
                        <div className="input-group mb-3 border rounded">
                          <Input
                            type="text"
                            className="form-control border-0"
                            name="s"
                            id="search"
                            placeholder="Search Keywords..."
                            onChange={(e) => setSearchField(e.target.value.toLowerCase())}
                          />
                          <button type="submit" className="input-group-text bg-white border-0" id="searchsubmit"><i className="uil uil-search"></i></button>
                        </div>
                      </Form>
                    </div>
                  </div>

                  <div className="d-flex justify-content-md-between align-items-center">
                    <div className="form custom-form">
                      <div className="mb-0">
                        <select
                          className="form-select form-control"
                          id="category"
                          onChange={changeFilter}
                        >
                          <option value={"All"}>All products</option>
                          <option value={"Gaming"}>Gaming</option>
                          <option value={"Beauty"}>Beauty</option>
                          <option value={"Health"}>Health</option>
                          <option value={"Women's Fashion"}>Women's Fashion</option>
                          <option value={"Men's Fashion"}>Men's Fashion</option>
                          <option value={"Accessories"}>Accessories</option>
                          <option value={"Gym and Fitness"}>Gym and Fitness</option>
                          <option value={"Electronics"}>Electronics</option>
                          <option value={"Others"}>Others</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={9} md={8} xs={12} className="mt-5 pt-2 mt-sm-0 pt-sm-0">

              <Row>
                {(curentProducts).map((product, key) => (
                  <Col xs={12} className="mt-4 pt-2" key={key}>
                  {product && (
                      <Card className="shop-list border-0 position-relative">
                        <ul className="label list-unstyled mb-0">
                          {product.featured && (
                            <li>
                              <Link
                                to="#"
                                className="badge badge-link rounded-pill bg-success"
                              >
                                Featured
                              </Link>
                            </li>
                          )}

                          {product.isMerch && (
                            <li>
                              <Link
                                to="#"
                                className="badge badge-link rounded-pill bg-warning"
                              >
                                Add your design
                              </Link>
                            </li>
                          )}
                        </ul>
                        <Row className="align-items-center g-0">
                          <Col lg={4} md={6}>
                            <div className="shop-image position-relative overflow-hidden">
                              <Link to="#" onClick={() => openModal(product)}>
                                <img
                                  src={product.picUrl}
                                  className="img-fluid"
                                  alt="S.W.H.A.G"
                                />
                              </Link>
                              <Link
                                to="#"
                                className="overlay-work"
                                onClick={() => openModal(product)}
                              >
                                <img
                                  src={product.picUrl}
                                  className="img-fluid"
                                  alt="S.W.H.A.G"
                                />
                              </Link>
                              {product.isOutofStock && (
                                <div className="overlay-work">
                                  <div className="py-2 bg-soft-dark rounded-bottom out-stock">
                                    <h6 className="mb-0 text-center">
                                      Out of stock
                                    </h6>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Col>

                          <Col lg={8} md={6}>
                            <CardBody className="content p-4">
                              <Link
                                to="/shop-product-detail"
                                className="text-dark product-name h6"
                              >
                                {product.name}
                              </Link>
                              <div className="d-lg-flex align-items-center mt-2 mb-3">

                                <h6 className="text-muted small fst-italic mb-0 me-3">
                                  NGN {product.cost}
                                </h6>
                              </div>
                              <p className="para-desc text-muted mb-1">
                                {product.description}
                              </p>
                              <ul className="list-unstyled mb-0">
                                <li className="mt-2 list-inline-item">
                                  <Link to="#" className="btn btn-primary" onClick={() => openModal(product)}>Add to your store</Link>
                                </li>
                                {payButton}
                              </ul>
                            </CardBody>
                          </Col>
                        </Row>
                      </Card>
                    )}
                  </Col>
                ))}

                <Modal isOpen={isModalOpen} toggle={closeModal} centered size="lg">
                  <ModalHeader toggle={closeModal}>{product.name}</ModalHeader>

                  <ModalBody className="p-4">
                    <Container fluid className="px-0">
                      <Row>
                        <Col lg={5}>
                          <div className="tiny-single-item">
                            <div className="tiny-slide"><img src={product.picUrl} className="img-fluid rounded" alt="" /></div>
                          </div>
                        </Col>

                        <Col lg={7} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                          <h4 className="title">{product.name}</h4>
                          <h5 className="mt-4">Costs:</h5>
                          <h5 className="text-muted">NGN {product.cost}</h5>
                          <h5 className="mt-4">Delivery Fee:</h5>
                          <h5 className="text-muted">NGN {product.deliveryFee}</h5>

                          <h5 className="mt-4">Overview :</h5>
                          <p className="text-muted">{product.description}</p>

                          <div className="row mt-4 pt-2">

                            <div className="col-12 mt-4">
                              <div className="d-flex shop-list align-items-center">
                                <h6 className="mb-0">Set your price:</h6>
                                <div className="ms-3">
                                  <div className="qty-icons">
                                    NGN{"  "}<input min="0" name="price" id="price" defaultValue={product.cost*2} type="number" className="btn btn-icon btn-soft-primary quantity" style={{width: 100}} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 mt-4">
                              <div className="d-flex shop-list align-items-center">
                                <h6 className="mb-0">Select your store:</h6>
                                <div className="ms-3">
                                  <div className="qty-icons">
                                    <select className="btn btn-icon btn-soft-primary quantity" id="store" style={{width: 200}}>
                                      {stores.map((store) => {
                                        return(
                                          <option key={store.id} value={store.id}>{store.storeName}</option>
                                        )
                                      })}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {(store.collections) && (
                              <div className="col-12 mt-4">
                                <div className="d-flex shop-list align-items-center">
                                  <h6 className="mb-0">Add product to a collection:</h6>
                                  <div className="ms-3">
                                    <div className="qty-icons">
                                      <select className="btn btn-icon btn-soft-primary quantity" id="collection" style={{width: 200}}>
                                        {store.collections.map((collection, index) => {
                                          return(
                                            <option key={index} value={collection.name}>{collection.name}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="mt-4 pt-2">
                            <Link to="#" className="btn btn-primary" onClick={() => addProduct(product)}>Add to your store</Link>
                          </div>
                          <div onClick={closeModal} className="btn btn-soft-primary ms-2">
                            Close
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </ModalBody>
                  {/* </div>
                  </div> */}
                </Modal>
                <Modal isOpen={isRequestModalOpen} toggle={closeModal} centered size="lg">
                  <ModalHeader toggle={closeModal}>Request a product</ModalHeader>

                  <ModalBody className="p-4">
                    <Container fluid className="px-0">
                      <Row>
                      <h4 className="card-title">Tell us which product you would like to sell and we will bring it on the platform</h4>
                      <div className="custom-form mt-3">
                        <div id="message"></div>
                        <Form
                          name="contact-form"
                          id="contact-form"
                        >
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Product Name <span className="text-danger">*</span>
                                </Label>
                                <div className="form-icon position-relative">
                                  <i>
                                    <FeatherIcon
                                      icon="package"
                                      className="fea icon-sm icons"
                                    />
                                  </i>
                                </div>
                                <Input
                                  name="name"
                                  id="productName"
                                  type="text"
                                  className="form-control ps-5"
                                  placeholder="Product Name:"
                                  required
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Your Name <span className="text-danger">*</span>
                                </Label>
                                <div className="form-icon position-relative">
                                  <i>
                                    <FeatherIcon
                                      icon="package"
                                      className="fea icon-sm icons"
                                    />
                                  </i>
                                </div>
                                <Input
                                  name="name"
                                  id="name"
                                  type="text"
                                  className="form-control ps-5"
                                  placeholder="Your Name:"
                                  required
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  What price would you sell it for <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="price"
                                  id="price"
                                  type="number"
                                  className="form-control ps-5"
                                  placeholder="10000"
                                  required
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12} className="text-center">
                              <div className="d-grid">
                                <input
                                  id="submit"
                                  name="send"
                                  className="submitBnt btn btn-primary"
                                  value="Request product"
                                  onClick={requestProduct}
                                />
                              </div>
                              <div id="simple-msg"></div>

                            </Col>
                          </Row>
                        </Form>
                      </div>
                      </Row>
                    </Container>
                  </ModalBody>
                  {/* </div>
                  </div> */}
                </Modal>
                <Modal isOpen={isPricingModalOpen} toggle={closeModal} centered size="lg">
                  <ModalBody className="p-4">
                  <Container fluid className="px-0">
                    <Pricing changePlan={changePlan} />
                  </Container>
                  </ModalBody>
                </Modal>
                <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success">
                    Your request has been sent successfully!
                  </Alert>
                </Snackbar>
                <Col xs={12} className="mt-4 pt-2">
                  <MyPagination productsPerPage={productsPerPage} totalProducts={products.length} paginate={paginate} currentPage={currentPage} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ProductList;
