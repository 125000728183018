import React from "react";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import ProductList from "./dashboard/ShopProductList.js";
import Store from "./dashboard/Store.js";
//import Merchant from "./dashboard/Merchant.js";
import Settings from "./dashboard/Settings.js";
import Drawer from './dashboard/Drawer';
import Profile from "./dashboard/Profile.js";

const useStyles = makeStyles({
  container: {
    display: 'flex'
  }
});

export default () => {
  const classes = useStyles();
  return (
    <div>
      <Drawer />
        <Switch>
          <Route exact from="/" render={props => <Profile {...props} />}/>
          <Route exact from="/Store" render={props => <Store {...props} />}/>
          {/* <Route exact from="/Merchant" render={props => <Merchant {...props} />}/> */}
          <Route exact from="/Settings" render={props => <Settings {...props} />}/>
        </Switch>
    </div>
  );
}