import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  CardBody,
  Label,
  Modal,
  ModalBody
} from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';

import {fire} from "../Fire.js";
import firebase from 'firebase';

const useStyles = makeStyles({
  root: {
  },
  media: {
    height: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  media2: {
    height: 200,
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

// function addHoursToDate(date, hours) {
//   return new Date(new Date(date).setHours(date.getHours() + hours));
// };

function useStore() {
  const [store, setStore] = React.useState({});
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        setStore(Object.assign(doc.data(), {storeId: doc.id,}));
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return store;
};

const EditStore = ({profile}) => {
  const classes = useStyles();
  const user = fire.auth().currentUser;
  const store = useStore();
  const [isOpenNewCollection, setOpenNewCollection] = React.useState(false);
  const [currentCollection, setCurrentCollection] = React.useState();

  
  const updateStore = (e) => {
    e.preventDefault();
    const storeName = document.getElementById("storeName").value;
    const heading = document.getElementById("heading").value;
    const subheading = document.getElementById("subheading").value;
    const ctaHeading = document.getElementById("ctaHeading").value;
    const ctaSubheading = document.getElementById("ctaSubheading").value;
    const deliveryFee = document.getElementById("deliveryFee").value;

    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .update({
      storeName: storeName,
      heading: heading,
      subheading: subheading,
      ctaHeading: ctaHeading,
      ctaSubheading: ctaSubheading,
      deliveryFee: deliveryFee,
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .update({
      storeName: storeName,
      heading: heading,
      subheading: subheading,
      ctaHeading: ctaHeading,
      ctaSubheading: ctaSubheading,
      deliveryFee: deliveryFee,
    });
  };
  const selectPic = (e) => {
    const image = e.target.files[0];
    const storeDocument = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId);
    const publicStoreDocument = fire.firestore().collection("stores").doc(store.storeId);
    const storageRef = fire.storage().ref('users/'+user.uid+'/stores/'+store.storeId);
    const fileRef = storageRef.child('mainImage');
    fileRef.put(image).then((e) => {
      fileRef.getDownloadURL().then((url) => {
        storeDocument
        .update({
          mainImage: url,
        });
        publicStoreDocument
        .update({
          mainImage: url,
        });
      });
    });
  };
  const selectPic2 = (e) => {
    const image = e.target.files[0];
    const storeDocument = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId);
    const publicStoreDocument = fire.firestore().collection("stores").doc(store.storeId);
    const storageRef = fire.storage().ref('users/'+user.uid+'/stores/'+store.storeId);
    const fileRef = storageRef.child('secondaryImage');
    fileRef.put(image).then((e) => {
      fileRef.getDownloadURL().then((url) => {
        storeDocument
        .update({
          secondaryImage: url,
        });
        publicStoreDocument
        .update({
          secondaryImage: url,
        });
      });
    });
  };

  const closeModal = () => {
    setOpenNewCollection(false);
  };

  const createCollection = (e) => {
    e.preventDefault();
    const collectionName = document.getElementById("collectionName").value;
    setOpenNewCollection(false);
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .update({
      collections: firebase.firestore.FieldValue.arrayUnion({name: collectionName, picUrl: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/images%2Fcollection%2Ffea3.jpg?alt=media&token=1e0dec2b-2a37-4924-a67d-31851570f0dc"}),
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .update({
      collections: firebase.firestore.FieldValue.arrayUnion({name: collectionName, picUrl: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/images%2Fcollection%2Ffea3.jpg?alt=media&token=1e0dec2b-2a37-4924-a67d-31851570f0dc"}),
    });
  };

  const deleteCollection = (col) => {
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .update({
      collections: firebase.firestore.FieldValue.arrayRemove(col),
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .update({
      collections: firebase.firestore.FieldValue.arrayRemove(col),
    });
  };

  const changeCollectionImage = (e) => {
    const image = e.target.files[0];
    const storeDocument = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId);
    const publicStoreDocument = fire.firestore().collection("stores").doc(store.storeId);
    const storageRef = fire.storage().ref('users/'+user.uid+'/stores/'+store.storeId);
    const fileRef = storageRef.child(currentCollection.name);
    fileRef.put(image).then((e) => {
      fileRef.getDownloadURL().then((url) => {
        storeDocument
        .update({
          collections: firebase.firestore.FieldValue.arrayRemove(currentCollection),
        });
        publicStoreDocument
        .update({
          collections: firebase.firestore.FieldValue.arrayRemove(currentCollection),
        });
        storeDocument
        .update({
          collections: firebase.firestore.FieldValue.arrayUnion({name: currentCollection.name, picUrl: url}),
        });
        publicStoreDocument
        .update({
          collections: firebase.firestore.FieldValue.arrayUnion({name: currentCollection.name, picUrl: url}),
        });
      });
    });
  };

  return (
    <React.Fragment>

        <Container>
          <Row>
            <Col md={4} xs={12}>
              <Card className="shadow rounded border-0">
                <CardBody className="py-5">
                  <h4 className="card-title">Edit your store!</h4>
                  <div className="custom-form mt-3">
                    <div id="message"></div>
                    <Form
                      method="post"
                      onSubmit={updateStore}
                      name="contact-form"
                      id="contact-form"
                    >
                      <Row>

                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Your Website link
                            </Label>
                            <Typography>
                              https://myswhag.com/{(store.storeName)}
                            </Typography>
                            <a target="_blank" href={"https://myswhag.com/"+(store.storeName)} rel="noreferrer">Preview</a>
                            <Typography>Changes made to your website may take up to 5 or 10 mins</Typography>
                          </div>
                        </Col>
                        <Col md={12} >
                          <Button color="primary" variant="contained" onClick={() => setOpenNewCollection(true)} style={{borderRadius: 20, width: '100%'}}>Create a collection</Button>
                        </Col>
                        <Col md={12} style={{borderStyle: 'solid', borderRadius: 20, paddingTop: 5, paddingBottom: 5, borderColor: '#2f55d4', marginTop: 10}}>
                          <InputLabel style={{display: 'flex', justifyContent: 'center',alignItems:'center', }}>
                            <Input type="file" onChange={selectPic} style={{display: 'none'}} />
                            <Typography color="primary">Change main image</Typography>
                          </InputLabel>
                        </Col>
                        <Col md={12} style={{borderStyle: 'solid', borderRadius: 20, paddingTop: 5, paddingBottom: 5, borderColor: '#2f55d4', marginTop: 10, marginBottom: 15}}>
                          <InputLabel style={{display: 'flex', justifyContent: 'center',alignItems:'center'}}>
                            <Input type="file" onChange={selectPic2} style={{display: 'none'}} />
                            <Typography color="primary">Change CTA image</Typography>
                          </InputLabel>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Your Store Name
                            </Label>
                            <Input
                              id="storeName"
                              type="text"
                              className="form-control "
                              defaultValue={store.storeName}
                              required
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Heading
                            </Label>
                            <Input
                              id="heading"
                              type="text"
                              className="form-control "
                              defaultValue={store.heading}
                              required
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Subheading
                            </Label>
                            <textarea id="subheading" type="text" className="form-control " rows="5" defaultValue={store.subheading} required />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              CTA Heading
                            </Label>
                            <Input
                              id="ctaHeading"
                              type="text"
                              className="form-control "
                              defaultValue={store.ctaHeading}
                              required
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              CTA Subheading
                            </Label>
                            <textarea id="ctaSubheading" type="text" className="form-control " rows="5" defaultValue={store.ctaSubheading} required
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Store Delivery Fee
                            </Label>
                            <Input
                              id="deliveryFee"
                              type="number"
                              className="form-control "
                              defaultValue={store.deliveryFee}
                              required
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} className="text-center">
                          <div className="d-grid">
                            <input
                              type="submit"
                              id="submit"
                              name="send"
                              className="submitBnt btn btn-primary"
                              value="Update Store"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={8} xs={12}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={store.mainImage}
                  title="Main image"
                >
                  <div style={{marginTop: 30, display: 'flex', justifyContent: 'space-between'}}>
                    <Typography gutterBottom variant="h5" style={{marginLeft: 40,}} >
                      {store.storeName}
                    </Typography>
                    <div className="btn btn-icon btn-primary " style={{marginRight: 40,}} >
                      <i className="uil uil-shopping-cart align-middle icons"></i>0
                    </div>
                  </div>
                  <div style={{marginBottom: 100, marginLeft: 40, maxWidth: '60%'}}>
                    <Typography variant="h4" component="p">
                      {store.heading}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {store.subheading}
                  </Typography>
                  </div>
                </CardMedia>
                {(store.collections) && (
                  <Row>
                  {store.collections.map((col, index) => {
                  return (
                    <Col lg={6} xs={12} key={index} className="mt-4 pt-2">
                      <Card
                        className="shop-features border-0 rounded overflow-hidden"
                      >
                      <img src={col.picUrl} className="img-fluid" alt="" />
                        <div className="category-title">
                          <h4>
                            {col.name}
                          </h4>
                          <div onClick={() => setCurrentCollection(col)}>
                          <InputLabel className="btn btn-sm btn-soft-primary mt-2">
                            <Input type="file" onChange={changeCollectionImage} style={{display: 'none'}} />
                            Change image
                          </InputLabel>
                          </div>
                          <div className="btn btn-sm btn-soft-primary mt-2" onClick={() => deleteCollection(col)}>
                            <div style={{color: 'red'}}>Delete Collection</div>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  );
                  })}
                  </Row>
                )}
                <CardContent>
                  <Typography variant="h6"  component="p">
                    Products
                  </Typography>
                  <Row style={{display: 'flex', justifyContent: 'center', marginTop: 30}}>
                  {[1,2,3,4].map(item => {
                    return (
                      <Col lg={3} xs={12} key={item}>
                        <CardMedia
                          image="https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/products%2F--default--%2Fdefault-product.jpg?alt=media&token=e5702835-a4b6-4131-a789-5d0207286b42"
                          style={{height: 150, marginTop: 20, }}
                          title="Product"
                        />
                      </Col>
                    );
                  })}
                  </Row>
                </CardContent>
                <CardMedia
                  className={classes.media2}
                  image={store.secondaryImage}
                  title="Main image"
                >
                  <div style={{marginLeft: 40, maxWidth: '80%',}}>
                    <Typography variant="h5" component="p">
                      {store.ctaHeading}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {store.ctaSubheading}
                    </Typography>
                  </div>
                </CardMedia>
              </CardActionArea>
            </Card>
            </Col>
          </Row>
          <Modal isOpen={isOpenNewCollection} toggle={closeModal} centered size="lg">

            <ModalBody className="p-4">
              <Container fluid className="px-0">
                <Row>
                  <h4 className="card-title">Create your Collection!</h4>
                  <h5 className="text-muted">Organise your products by adding them to collections</h5>
                  <div className="custom-form mt-3">
                    <Form
                      method="post"
                      onSubmit={createCollection}
                      name="contact-form"
                      id="contact-form"
                    >
                      <Row>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Your Collection Name <span className="text-danger">*</span>
                            </Label>
                            <Input
                              id="collectionName"
                              type="text"
                              className="form-control ps-5"
                              placeholder="Collection Name: e.g Clothes"
                              required
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} className="text-center">
                          <div className="d-grid">
                            <input
                              type="submit"
                              id="submit"
                              name="send"
                              className="submitBnt btn btn-primary"
                              value="Create collection"
                            />
                          </div>
                          <div id="simple-msg"></div>
                          <div onClick={closeModal} className="btn btn-soft-primary ms-2">
                            Close
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
        </Container>
    </React.Fragment>
  );
};


export default EditStore;
