import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import {fire} from "../Fire.js";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function useStore() {
  const [store, setStore] = React.useState({});
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        setStore(Object.assign(doc.data(), {storeId: doc.id,}));
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return store;
};

const OrdersTable = ({orders, headers}) => {
  const user = fire.auth().currentUser;
  const classes = useStyles();
  let history = useHistory();
  const store = useStore();
  const [products, setProducts] = React.useState([]);
  const [singleOrder, setSingleOrder] = React.useState({});
  const [isModalOpen, setOpenModal] = React.useState(false);
  const [timestamp, setTimestamp] = React.useState("");
  var totalRevenue = 0;

  const openModal = (e) => {
    setOpenModal(true);
    setProducts(e.products);
    setSingleOrder(e);
    setTimestamp(e.timestamp.toDate().toDateString());
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const confirmOrder = (e) => {
    fire.firestore().collection("users").doc(user.uid).collection("stores").doc(e.storeId).collection("orders").doc(singleOrder.orderId).collection("products").doc(e.productId).update({
      status: "Confirmed and awaiting delivery",
    });
    fire.firestore().collection("users").doc(singleOrder.storeUserUid).collection("stores").doc(singleOrder.storeId).collection("orders").doc(singleOrder.orderId).collection("products").doc(e.productId).update({
      status: "Confirmed and awaiting delivery",
    });
    setOpenModal(false);
    history.push("/Settings");
  };

  const confirmDelivery = (e) => {
    const logisticsCompany = document.getElementById(e.productId+"logisticsCompany").value;
    const deliveryDays = document.getElementById(e.productId+"deliveryDays").value;
    const message = document.getElementById(e.productId+"message").value;
    const deliveryConfirm = fire.functions().httpsCallable('deliveryConfirm');
    deliveryConfirm({orderId: singleOrder.orderId, customerEmail: singleOrder.customerEmail, storeEmail: singleOrder.storeEmail, merchantEmail: user.email, merchantName: singleOrder.storeName, name: e.name, customerFirstName: singleOrder.customerFirstName, items: e.items, picUrl: e.picUrl, deliveryAddress: singleOrder.deliveryAddress, logisticsCompany: logisticsCompany, deliveryDays: deliveryDays, message: message,}).then(result => {
      console.log(result.data);
    });
    fire.firestore().collection("users").doc(singleOrder.storeUserUid).collection("stores").doc(singleOrder.storeId).collection("orders").doc(singleOrder.orderId).collection("products").doc(e.productId).update({
      status: "Delivered",
     });
     fire.firestore().collection("users").doc(user.uid).collection("stores").doc(e.storeId).collection("orders").doc(singleOrder.orderId).collection("products").doc(e.productId).update({
      status: "Delivered",
     });
    setOpenModal(false);
    history.push("/");
  };

  return (
    <div>
      <Row>
        {orders.map((order) => {
          if (order.orderId !== "--stats--") {
            var revenue = 0;
            var productCost = 0;
            var deliveryFee = 0;
            var storeDeliveryFee = 0;
            order.products.forEach(product => {
              if(product.dropshipping === true){
                revenue = revenue + Number(product.price);
                deliveryFee = deliveryFee + Number(product.deliveryFee);
                productCost = productCost + Number(product.cost);
              } else {
                revenue = revenue + Number(product.cost);
                storeDeliveryFee = Number(store.deliveryFee);
              };
            });
            revenue = revenue + storeDeliveryFee;
            totalRevenue = totalRevenue + revenue;
          }
        })}
        <Col key={"sales"} md={6} xs={12} className="mt-4 pt-2">
          <Card>
            <CardContent>
              <Typography>Total revenue</Typography>
              <Typography  variant="h5" component="h3">
                NGN {totalRevenue}
              </Typography>
            </CardContent>
          </Card>
        </Col>
        <Col key={"payment"} md={6} xs={12} className="mt-4 pt-2">
          <Card>
            <CardContent>
              <Typography>Payment will be made to your bank account the next working day </Typography>
            </CardContent>
          </Card>
        </Col>
        <Col key={"ordersTable"} xs={12} >
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Order id</TableCell>
                  <TableCell align="right">Manage Order</TableCell>
                  <TableCell align="right">Revenue</TableCell>
                  <TableCell align="right">Product cost</TableCell>
                  <TableCell align="right">Shipping cost</TableCell>
                  <TableCell align="right">Profit</TableCell>
                  <TableCell align="right">Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => {
                  if (order.orderId !== "--stats--") {
                    var revenue = 0;
                    var productCost = 0;
                    var deliveryFee = 0;
                    var storeDeliveryFee = 0;
                    let date;
                    order.products.forEach(product => {
                      if(product.dropshipping === true){
                        revenue = revenue + Number(product.price);
                        deliveryFee = deliveryFee + Number(product.deliveryFee);
                        productCost = productCost + Number(product.cost);
                      } else {
                        revenue = revenue + Number(product.cost);
                        storeDeliveryFee = Number(store.deliveryFee);
                      };
                    });
                    date = order.timestamp.toDate().toDateString();
                    return (
                      <TableRow key={order.orderId} onClick={() => openModal(order)}>
                        <TableCell component="th" scope="row">
                          {order.orderId}
                        </TableCell>
                        <TableCell align="right"><div style={{color: 'blue'}}>manage</div></TableCell>
                        <TableCell align="right">NGN {revenue + storeDeliveryFee}</TableCell>
                        <TableCell align="right">NGN {productCost}</TableCell>
                        <TableCell align="right">NGN {deliveryFee + storeDeliveryFee}</TableCell>
                        <TableCell align="right">NGN {revenue - (productCost+deliveryFee)}</TableCell>
                        <TableCell align="right">{date}</TableCell>
                      </TableRow>
                    );
                  } else {
                    return null;
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
      </Row>
      <Modal isOpen={isModalOpen} toggle={closeModal} centered size="lg">
        <ModalHeader toggle={closeModal}>Products</ModalHeader>
        <div onClick={closeModal} className="btn btn-soft-primary ms-2">
          Close
        </div>
        <ModalBody className="p-4">
        <h5 className="mt-4">Customer details :</h5>
        <p className="text-muted">Name: {singleOrder.customerFirstName} {singleOrder.customerLastName}</p>
        <p className="text-muted">Email: {singleOrder.customerEmail} </p>
        <p className="text-muted">PhoneNumber: +{singleOrder.customerPhoneNumber} </p>
        <p className="text-muted">Delivery Address: {singleOrder.deliveryAddress} </p>
        <p className="text-muted">Date: {timestamp} </p>

        {products.map((product, key) =>  {
          if (product.dropshipping === true) {
            return (
              <Container fluid className="px-0">
              <Row>
                <Col lg={5}>
                  <div className="tiny-single-item">
                    <div className="tiny-slide"><img src={product.picUrl} className="img-fluid rounded" alt="" /></div>
                  </div>
                </Col>

                <Col lg={7} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                  <h4 className="title">{product.name}</h4>
                  <p className="text-muted">Merchant Email: {product.merchantEmail} </p>
                  <p className="text-muted">Contact merchant for shipping updates </p>
                  <h5 className="mt-4">Revenue:</h5>
                  <h5 className="text-muted">NGN {product.price}</h5>
                  <h5 className="mt-4">Costs:</h5>
                  <h5 className="text-muted">NGN {product.cost}</h5>
                  <h5 className="mt-4">Delivery Fee:</h5>
                  <h5 className="text-muted">NGN {product.deliveryFee}</h5>
                  <h5 className="mt-4">Quantity:</h5>
                  <h5 className="text-muted">{product.items}</h5>
                  {product.multipleSizes &&(
                    <div>
                      <h5 className="mt-4">Variant:</h5>
                      <h5 className="text-muted">{product.size}</h5>
                    </div>
                  )}
                  <h5 className="mt-4">Status:</h5>
                  <h5 className="text-muted">{product.status}</h5>
                </Col>
              </Row>
            </Container>
            );
          } else {
            return (
              <Container fluid className="px-0">
              <Row>
                <Col lg={5}>
                  <div className="tiny-single-item">
                    <div className="tiny-slide"><img src={product.picUrl} className="img-fluid rounded" alt="" /></div>
                  </div>
                </Col>

                <Col lg={7} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                  <h4 className="title">{product.name}</h4>
                  <h5 className="mt-4">Revenue:</h5>
                  <h5 className="text-muted">NGN {product.cost}</h5>
                  <h5 className="mt-4">Delivery Fee:</h5>
                  <h5 className="text-muted">NGN {store.deliveryFee}</h5>
                  <h5 className="mt-4">Quantity:</h5>
                  <h5 className="text-muted">{product.items}</h5>
                  {product.multipleSizes &&(
                    <div>
                      <h5 className="mt-4">Variant:</h5>
                      <h5 className="text-muted">{product.size}</h5>
                    </div>
                  )}
                  <h5 className="mt-4">Status:</h5>
                  <h5 className="text-muted">{product.status}</h5>
                  {(product.status === "Pending confirmation") &&(
                    <Button variant="contained" color="primary" onClick={() => confirmOrder(product)}>Confirm order</Button>
                  )}
                  {(product.status === "Confirmed and awaiting delivery") &&(
                    <div>
                    <h5 className="text-muted">Have you delivered the product?</h5>
                      <Col lg="12">
                      <div className="mb-3">
                        <Label className="form-label">What logistics company is delivering the product :</Label>
                        <Input
                          id={product.productId+"logisticsCompany"}
                          type="text"
                          className="form-control ps-5"
                          required
                        />
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="mb-3">
                        <Label className="form-label">How many days will it take to deliver the product :</Label>
                        <Input
                          id={product.productId+"deliveryDays"}
                          type="number"
                          className="form-control ps-5"
                          required
                        />
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="mb-3">
                        <Label className="form-label">Any other information the customer will need to recieve the product :</Label>
                        <textarea
                          id={product.productId+"message"}
                          type="text"
                          className="form-control ps-5"
                          rows={3}
                          required
                        />
                      </div>
                    </Col>
                      <Button variant="contained" color="primary" onClick={() => confirmDelivery(product)}>Notify customer</Button>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
           );
          };
          })}
          
        </ModalBody>
      </Modal>
    </div>
  );
}

export default OrdersTable;