import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Icons
import FeatherIcon from "feather-icons-react";

import { useHistory } from "react-router-dom";
import {fire, fire2} from "../../../Fire.js";
import firebase from 'firebase';
import ReactPixel from 'react-facebook-pixel';

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};
ReactPixel.init('782596949083299', options);

const PageSignupThree = props =>  {
  let history = useHistory();
  const increment = firebase.firestore.FieldValue.increment(1);
  const db = fire.firestore();
  const [store, setStore] = React.useState(null);
  
  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const storeName = queryParams.get('store');
    if(storeName != null){
      setStore(storeName);
    };
  }, []);

  const signUp = (e) => {
    e.preventDefault();
    const firstName = document.getElementById('firstName').value;
    const lastName = document.getElementById('lastName').value;
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    fire.auth().createUserWithEmailAndPassword(email, password)
      .then((cred) => {
        cred.user.sendEmailVerification().then(function() {
          // Email sent.
        }).catch(function(error) {
          // An error happened.
        });
        db.collection("shoppers").doc((cred.user.email).toLowerCase()).set({
          firstName: firstName,
          lastName: lastName,
          email: (cred.user.email).toLowerCase(),
          dateJoined: firebase.firestore.FieldValue.serverTimestamp(),
          shoppingInterests: [],
        }, {merge: true});
        db.collection("userCount").doc("--stats--").update({
          currentShoppers: increment,
        });
        db.collection("userCount").doc((cred.user.email).toLowerCase()).set({
          firstName: firstName,
          email: (cred.user.email).toLowerCase(),
          timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
        ReactPixel.trackSingle('782596949083299', 'CompleteRegistrationShopper', {currency: "NGN", value: 1});
        const newsletterShopper = fire2.functions().httpsCallable('newsletterShopper');
        newsletterShopper({ firstName: firstName, lastName: lastName, email: email}).then(res => {
          console.log(res.data);
        });

        const welcomeShopper = fire2.functions().httpsCallable('welcomeShopper');
        welcomeShopper({ firstName: firstName, email: email}).then(res => {
          console.log(res.data);
        });
        // const addUserContact = fire.functions().httpsCallable('addUserContact');
        // addUserContact({ name: firstName, email: email}).then(res => {
        //   console.log(res.data);
        // });
        // const welcomeUser = fire.functions().httpsCallable('welcomeUser');
        // welcomeUser({ name: firstName, email: email}).then(res => {
        //   console.log(res.data);
        // });
        return true;
      })
      .catch((err) => {
        console.log("Error: "+ err.toString());
      });
      if(store == null){
        history.push("/");
      }
      if (store != null){
        history.push("/?store="+store);
      }
  };
  // const googleSignup = () => {
  //   var provider = new firebase.auth.GoogleAuthProvider();

  //   firebase.auth()
  //   .signInWithPopup(provider)
  //   .then((result) => {
  //     /** @type {firebase.auth.OAuthCredential} */
  //     var credential = result.credential;

  //     // This gives you a Google Access Token. You can use it to access the Google API.
  //     var token = credential.accessToken;
  //     // The signed-in user info.
  //     var user = result.user;
  //     console.log(user);
  //     db.collection("users").doc(user.uid).set({
  //       firstName: user.displayName,
  //       email: user.email,
  //       googleToken: token,
  //       store: false,
  //       isMerchant: false,
  //       subscribed: false,
  //       dateJoined: firebase.firestore.FieldValue.serverTimestamp(),
  //     });
  //     history.push("/");
  //     // ...
  //   }).catch((error) => {
  //     // Handle Errors here.
  //     var errorCode = error.code;
  //     var errorMessage = error.message;
  //     // The email of the user's account used.
  //     var email = error.email;
  //     // The firebase.auth.AuthCredential type that was used.
  //     var credential = error.credential;
  //     // ...
  //   });
  // };
  return (
    <React.Fragment>
      <div className="back-to-home rounded d-none d-sm-block">
        <Link
          to="//swhag.co"
          target="_blank" rel="noopener noreferrer"
          className="btn btn-icon btn-primary"
        >
          <i>
            <FeatherIcon icon="home" className="icons" />
          </i>
        </Link>
      </div>

      <section className="bg-home bg-circle-gradiant d-flex align-items-center">
        <div className="bg-overlay bg-overlay-white"></div>
        <Container>
          <Row className="justify-content-center">
            <Col lg={5} md={8}>
              <Card className="login-page bg-white shadow rounded border-0">
                <CardBody>
                  <h4 className="card-title text-center">Signup</h4>
                  <AvForm className="login-form mt-4" id="form">
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            First name <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <AvField
                            type="text"
                            className="form-control ps-5"
                            errorMessage="Enter First Name"
                            validate={{ required: { value: true } }}
                            placeholder="First Name"
                            name="fname"
                            id="firstName"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Last name <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user-check"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <AvField
                            type="text"
                            className="form-control ps-5"
                            errorMessage="Enter Last Name"
                            validate={{ required: { value: true } }}
                            placeholder="Last Name"
                            name="lname"
                            id="lastName"
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Your Email <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="mail"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <AvField
                            type="email"
                            className="form-control ps-5"
                            errorMessage="Invalid Email"
                            validate={{
                              required: { value: true },
                              email: { value: true },
                            }}
                            placeholder="Email"
                            name="email"
                            id="email"
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Password <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="lock"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <AvField
                            type="password"
                            id="password"
                            className="form-control ps-5"
                            errorMessage="Enter Password"
                            validate={{ required: { value: true } }}
                            name="password"
                            placeholder="Password"
                          />
                        </div>
                      </Col>
                      {/* <Col md={12}>
                        <div className="mb-3">
                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheck1"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="customCheck1"
                            >
                              I Accept{" "}
                              <Link to="#" className="text-primary">
                                Terms And Condition
                              </Link>
                            </Label>
                          </div>
                        </div>
                      </Col> */}
                      <Col lg={12} className="mb-0">
                        <div className="d-grid">
                          <Button color="primary" onClick={signUp}>
                            Register
                          </Button>
                        </div>
                      </Col>
                      {/* <Col lg={12} className="mt-4 text-center">
                        <h6>Or Signup With</h6>
                        <Row>
                          <div className="col-6 mt-3">
                            <div className="d-grid">
                              <Link to="#" className="btn btn-light"><i className="mdi mdi-facebook text-primary"></i> Facebook</Link>
                            </div>
                          </div> 

                          <div className="col-12 mt-3">
                            <div className="d-grid">
                              <Link to="#" onClick={googleSignup} className="btn btn-light"><i className="mdi mdi-google text-danger"></i> Google</Link>
                            </div>
                          </div>
                        </Row>
                      </Col> */}
                      <Col xs={12} className="text-center">
                        <p className="mb-0 mt-3">
                          <small className="text-dark me-2">
                            Already have an account ?
                          </small>{" "}
                          {(store != null)&& (
                            <Link
                              to={"/?store="+store}
                              className="text-dark fw-bold"
                            >
                              Sign In
                            </Link>
                          )}
                          {(store == null)&& (
                            <Link
                              to="/"
                              className="text-dark fw-bold"
                            >
                              Sign In
                            </Link>
                          )}
                        </p>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default PageSignupThree;
