import React from 'react';
import clsx from 'clsx';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from "react-router-dom";
import SettingsIcon from '@material-ui/icons/Settings';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import StorefrontIcon from '@material-ui/icons/Storefront';
//import StoreIcon from '@material-ui/icons/Store';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Badge from '@material-ui/core/Badge';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


import {fire} from "../Fire.js";
function logout() {
    fire.auth().signOut();
  };

const drawerWidth = 240;

const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#2f55d4',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#f56565',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    background: "#2f55d4",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  menuButton1: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  drawer: {

  }
}));

const MyDrawer = props => {
  const { history } = props;
  const classes = useStyles();
  const [open] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);



  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    };

    setOpenDrawer(open);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button key={"Product"} onClick={() => history.push('/')}>
          <ListItemIcon><LocalGroceryStoreIcon /></ListItemIcon>
          <ListItemText primary={"Products"} />
        </ListItem>
        <ListItem button key={"Store"} onClick={() => history.push('/Store')}>
          <ListItemIcon><StorefrontIcon /></ListItemIcon>
          <ListItemText primary={"My Store"} />
        </ListItem>
        {/* <ListItem button key={"Merchant"} onClick={() => history.push('/Merchant')}>
          <ListItemIcon>
            <Badge color="secondary">
              <StoreIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary={"Merchant"} />
        </ListItem> */}
        <ListItem button key={"Settings"} onClick={() => history.push('/Settings')}>
          <ListItemIcon><SettingsIcon /></ListItemIcon>
          <ListItemText primary={"Settings"} />
        </ListItem>
        <ListItem button key={"Logout"} onClick={logout}>
          <ListItemIcon><ExitToAppIcon /></ListItemIcon>
          <ListItemText primary={"Logout"} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
          <AppBar position="fixed" className={clsx(classes.appBar, open && classes.appBarShift)}>
              <Toolbar className={classes.toolbar}>
              {/* <Button onClick={toggleDrawer("top", true)} style={{color: "#ffffff"}}><MenuIcon /></Button>
              <Drawer anchor={"top"} open={openDrawer} onClose={toggleDrawer("top", false)}>
                {list("top")}
              </Drawer> */}
              <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} >
                  SWHAG
              </Typography>
              {/* <IconButton color="inherit" onClick={() => history.push('/Store')} >
                <Badge color="secondary">
                  <StorefrontIcon />
                </Badge>
              </IconButton> */}
              {/* <IconButton color="inherit" onClick={() => history.push('/')} >
                <HomeIcon />
              </IconButton>
              <IconButton color="inherit" onClick={() => history.push('/AffiliateProgram')} >
                <Badge badgeContent={numInvites} color="secondary">
                  <GroupAddIcon />
                </Badge>
              </IconButton>
              <IconButton color="inherit" onClick={() => history.push('/Settings')} >
                <SettingsIcon />
              </IconButton> */}
              </Toolbar>
          </AppBar>
        </div>
      </ThemeProvider>
  );
}

export default withRouter(MyDrawer);